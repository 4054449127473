//Main components
import React from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import BoardImg from "../../components/images/board.png";

function SafeGuard() {

    const navigate = useNavigate();

    return (
        <div>

            <Nav />

            <section class="page-title title-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>Safe guarding your values</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Safe guarding your values</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <section class="about-section ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="about-text">
                                <div class="section-title">
                                    <h2>Safe guarding your values</h2>
                                </div>

                                <p>
                                    Success in life has many great benefits, including the ability to invest in objects of
                                    value. After working hard to acquire these items, you want to make sure that your
                                    valuables are protected, as certain items are easy to carry off and frequently the
                                    target of would-be thieves.
                                    Enjoy the things that matter without fear or worry by taking the following
                                    precautions:
                                </p>

                                <h4>1.  Keep an Updated Inventory</h4>
                                <p>
                                    Creating a catalogue of valuables will help ensure that everything is where it
                                    should be, and to more easily target an absence if something goes missing. In
                                    addition, you’ll be able to prove ownership to authorities or insurance agents in
                                    case of emergency. Having a dedicated file for receipts and notes about
                                    purchases is a great start in protecting these valuables. Keep electronic records
                                    by scanning these documents; you won’t have to worry about digging through the
                                    hard copies trying to find a proof of purchase. Another great way to start an
                                    inventory of your jewelry is to use a video camera to create a visual record. Walk
                                    through your house and narrate the video by describing each item as you film it,
                                    then upload the final product in a secure place. Make sure to redo your catalogue
                                    at least once a year – or more frequently depending on how often the content
                                    changes.
                                </p>

                                <h4>2.  Store Valuables in a Safe Location</h4>
                                <p>
                                    There’s no better place to store truly irreplaceable items than behind a solid,
                                    locking door. You might think that you’ve found the “perfect hiding spot,” or that
                                    no one would have the opportunity to access your belongings, but you might be
                                    surprised at how many people come and go from your personal spaces on a
                                    regular basis. There’s always the chance that a burglar will break in to your home
                                    or place of work, but as with other crimes, often the responsible party is someone
                                    you know, such as an acquaintance, employee, or contractor. Finally, starting the
                                    habit of always putting your valuables in your safe will help you avoid the
                                    unfortunate scenario of wondering if you misplaced an important item, or if it was
                                    taken.
                                </p>

                                <h4>3.    Insure Valuables for the Right Amount</h4>
                                <p>
                                    Normal homeowner’s insurance doesn’t cover certain items, even if the value of
                                    those items falls within your limit for damages. Expensive items will most likely
                                    need to be cataloged and insured separately. An insurance agent will gladly help
                                    you find the amount of coverage that’s right for you, and will even come to your
                                    home or place of work at your convenience. Make sure to compare several offers
                                    and do some research before committing to a policy and prevent ending up with
                                    the wrong type of protection.
                                    It doesn’t take a lot to save you the stress, anger, and potential heartache of lost
                                    or stolen treasures. Protect your valuable belongings using a secure, quality
                                    jewelry safe.
                                </p>
                            </div>
                        </div>
                        {/* <div class="col-lg-6">
                            <div class="about-img">
                                <img src={BoardImg} alt="about image" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <section class="use-section pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Easiest Way To Use</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>1</span>
                                <i class='flaticon-website'></i>
                                <h3>Browse Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>2</span>
                                <i class='flaticon-recruitment'></i>
                                <h3>Find Your Vaccancy</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="use-text">
                                <span>3</span>
                                <i class='flaticon-login'></i>
                                <h3>Submit Resume</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="why-choose-two pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Why You Choose Us Among Other Job Site?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-resume"></i>
                                <h3>Advertise Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-recruitment"></i>
                                <h3>Recruiter Profiles</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="choose-card">
                                <i class="flaticon-employee"></i>
                                <h3>Find Your Dream Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="grow-business pb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="grow-text">
                                <div class="section-title">
                                    <h2>Grow Your Business Faster With Premium Advertising</h2>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                                </p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit.
                                </p>

                                <div class="theme-btn">
                                    <a href="#" class="default-btn">Checkout More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="grow-img">
                                <img src="assets/img/grow-img.jpg" alt="grow image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="counter-section pt-100 pb-70">
                <div class="container">
                    <div class="row counter-area">
                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-resume"></i>
                                <h2><span>1225</span></h2>
                                <p>Job Posted</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-recruitment"></i>
                                <h2><span>145</span></h2>
                                <p>Job Filed</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-portfolio"></i>
                                <h2><span>170</span></h2>
                                <p>Company</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-employee"></i>
                                <h2><span>125</span></h2>
                                <p>Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-style-two ptb-100">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>What Client’s Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="row">
                        <div class="testimonial-slider-two owl-carousel owl-theme">
                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Alisa Meair</h3>
                                    <p>CEO of  Company</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Adam Smith</h3>
                                    <p>Web Developer</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>John Doe</h3>
                                    <p>Graphics Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="blog-section pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>News, Tips & Articles</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/1.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            How to Indroduce in Yourself in Job Interview?
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/2.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            Looking for Highly Motivated Product to Build
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/3.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            The Reason Why Software Develope is the Best Job
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
}

export default SafeGuard;

