import { useNavigate } from "react-router-dom";
import Logo from "../components/images/logo-white.png";

function Footer() {

    const navigate = useNavigate();

    return (
        <div>
            <footer class="footer-area pt-100 pb-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget">
                                <div class="footer-logo">
                                    <a href="index.html">
                                        <img src={Logo} style={{ width: "70%" }} alt="logo" />
                                    </a>
                                </div>

                                <p>
                                    Koti Koti aims to create a trusted, dynamic and efficient platform that connects buyers and sellers in urgent need of products and services.
                                </p>

                                <div class="footer-social">
                                    <a href="https://facebook.com/teamkotikoti" target="_blank"><i class="zmdi zmdi-facebook-box" style={{ color: "#fd1616" }}></i></a>
                                    <a href="#" target="_blank"><i class="zmdi zmdi-google-plus-box" style={{ color: "#fd1616" }}></i></a>
                                    <a href="#" target="_blank"><i class="zmdi zmdi-linkedin-box" style={{ color: "#fd1616" }}></i></a>
                                    <a href="https://instagram.com/kotikotiglobal" target="_blank"><i class="zmdi zmdi-instagram" style={{ color: "#fd1616" }}></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget pl-60">
                                <h3>For Professional</h3>
                                <ul>
                                    {/* <li>
                                        <a style={{ cursor: "pointer" }} onClick={() => { navigate("/jobs") }}>
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Browse Leads
                                        </a>
                                    </li> */}
                                    {/* <li>
                                        <a href="account.html">
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Account
                                        </a>
                                    </li>
                                    <li>
                                        <a href="catagories.html">
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Browse Categories
                                        </a>
                                    </li>
                                    <li>
                                        <a href="resume.html">
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Resume
                                        </a>
                                    </li> */}
                                    <li>
                                        <a style={{ cursor: "pointer" }} onClick={() => { navigate("/login") }}>
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Sign in
                                        </a>
                                    </li>
                                    <li>
                                        <a style={{ cursor: "pointer" }} onClick={() => { navigate("/register") }}>
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Sign Up
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget pl-60">
                                <h3>Quick Links</h3>
                                <ul>
                                    <li>
                                        <a onClick={() => { navigate("/dashboard") }} style={{ cursor: "pointer" }}>
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Home
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => { navigate("/about") }} style={{ cursor: "pointer" }}>
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            About
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => { navigate("/terms") }} style={{ cursor: "pointer" }}>
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a onClick={() => { navigate("/terms") }} style={{ cursor: "pointer" }}>
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Terms and conditions
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a href="privacy.html">
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Privacy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="contact.html">
                                            <i class='bx bx-chevrons-right bx-tada'></i>
                                            Contact
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="footer-widget footer-info">
                                <h3>Contact Us</h3>
                                <ul>
                                    <li>
                                        <span>
                                            <i class='bx bxs-phone'></i>
                                            Phone:
                                        </span>
                                        <a href="tel:+263783333311">
                                            +263783333311
                                        </a>
                                        <a href="tel:+263713555511">
                                            +263713555511
                                        </a>
                                    </li>

                                    <li>
                                        <span>
                                            <i class='bx bxs-envelope'></i>
                                            Email:
                                        </span>
                                        <a href="mailto:team@kotikoti.co.zw">
                                            team@kotikoti.co.zw
                                        </a>
                                    </li>

                                    <li>
                                        <span>
                                            <i class='bx bx-location-plus'></i>
                                            Address:
                                        </span>
                                        22 Clyde Road, Eastlea, Harare
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* <div class="top-btn">
                <i class='bx bx-chevrons-up bx-fade-up'></i>
            </div> */}
        </div>
    );
}

export default Footer;