//Main Components
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

//Project components
import { TOKEN_KEY, PHONE, ROLE, NAME, USER } from "./Constants";
import Logo from "../components/images/logo-white.png";

function Nav() {

    const [getRole, setRole] = useState('');
    const [getUsername, setUsername] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const role = localStorage.getItem(ROLE);
        const loggedIn = localStorage.getItem(TOKEN_KEY);
        setUsername(localStorage.getItem(NAME));

        setIsUserLoggedIn(loggedIn);

        setRole(role);
    }, [])

    const logout = () => {
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(PHONE);
        localStorage.removeItem(ROLE);
        localStorage.removeItem(NAME);
        localStorage.removeItem(USER);
        navigate("/login");
    }

    const savedJobs = () => {
        navigate("/savedjobs");
    }

    const profile = () => {
        navigate("/profile");
    }

    const appliedJobs = () => {
        navigate("/appliedjobs");
    }

    const smallerThan900 = {
        width: "30%",
        position: "absolute",
        //backgroundColor: "white"
    }

    return (
        <div>
            {/* <div className="loader-content">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="sk-circle">
                            <div className="sk-circle1 sk-child"></div>
                            <div className="sk-circle2 sk-child"></div>
                            <div className="sk-circle3 sk-child"></div>
                            <div className="sk-circle4 sk-child"></div>
                            <div className="sk-circle5 sk-child"></div>
                            <div className="sk-circle6 sk-child"></div>
                            <div className="sk-circle7 sk-child"></div>
                            <div className="sk-circle8 sk-child"></div>
                            <div className="sk-circle9 sk-child"></div>
                            <div className="sk-circle10 sk-child"></div>
                            <div className="sk-circle11 sk-child"></div>
                            <div className="sk-circle12 sk-child"></div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="navbar-area">
                <div className="mobile-nav">
                    <Link to="/dashboard" className="logo">
                        <img src={Logo} alt="logo" style={smallerThan900} />
                    </Link>
                </div>

                <div className="main-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link className="navbar-brand" to="/dashboard">
                                <img src={Logo} style={{ width: "18%", height: "10%" }} alt="logo" />
                            </Link>
                            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul className="navbar-nav m-auto">
                                    <li className="nav-item">
                                        <Link to="/dashboard" className="nav-link active">How it works</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/about" className="nav-link">About</Link>
                                    </li> */}
                                    {getRole ? (getRole !== "customer" ?
                                        <li className="nav-item">
                                            <Link to="/jobs" className="nav-link">Jobs</Link>
                                        </li>
                                        : null)
                                        : null
                                    }
                                    {/* <li className="nav-item">
                                        <Link to="/candidates" className="nav-link">Candidates</Link>
                                    </li> */}
                                    {/* <li className="nav-item">
                                        <Link to="/contact" className="nav-link">Contact Us</Link>
                                    </li> */}
                                    {/* <li class="nav-item">
                                        <a href="#" class="nav-link dropdown-toggle">Member</a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a href="sign-in.html" class="nav-link">Sign In</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="sign-up.html" class="nav-link">Sign Up</a>
                                            </li>
                                            <li class="nav-item">
                                                <a href="reset-password.html" class="nav-link">Reset
                                                    Password</a>
                                            </li>
                                        </ul>
                                    </li> */}
                                </ul>

                                {isUserLoggedIn ?
                                    <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                        <ul className="navbar-nav m-auto">
                                            <li class="nav-item" style={{ cursor: "pointer" }}>
                                                <a
                                                    href="#"
                                                    class="nav-link dropdown-toggle"
                                                    style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
                                                >
                                                    <i class="zmdi zmdi-account-circle" style={{ fontSize: 30 }}></i>
                                                    {getUsername}
                                                    <i class="zmdi zmdi-chevron-down" style={{ fontSize: 15, marginLeft: 5 }}></i>
                                                </a>
                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <a onClick={profile} class="nav-link">Profile</a>
                                                    </li>
                                                    {getRole ? (getRole !== "customer" ?
                                                        <div>
                                                            <li class="nav-item">
                                                                {/* <a onClick={savedJobs} class="nav-link">Saved Jobs</a> */}
                                                                <a class="nav-link">My Responses</a>
                                                            </li>
                                                            <li class="nav-item">
                                                                <a onClick={appliedJobs} class="nav-link">Applied Jobs</a>
                                                            </li>
                                                        </div>
                                                        : null)
                                                        : null
                                                    }
                                                    <li class="nav-item">
                                                        <a onClick={logout} class="nav-link">Logout</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    <div className="other-option">
                                        <Link to="/login" className="signup-btn">Login</Link>
                                        <Link to="/register" className="signin-btn">Register as professional</Link>
                                    </div>
                                }
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Nav;