//Main components
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

//Project components
import Job from './pages/Job';
import Jobs from './pages/Jobs';
import Terms from './pages/Terms';
import JobAdd from './pages/JobAdd';
import Profile from './pages/Profile';
import Contact from './pages/Contact';
import AboutUs from './pages/About-us';
import Login from './pages/auth/Login';
import Category from './pages/Category';
import SavedJobs from './pages/SavedJobs';
import Dashboard from './pages/Dashboard';
import Candidate from './pages/Candidate';
import Candidates from './pages/Candidates';
import Register from './pages/auth/Register';
import AppliedJobs from './pages/AppliedJobs';
import Introduce from './pages/blog/Introduce';
import SafeGuard from './pages/blog/SafeGuard';
import SearchResults from './pages/SearchResults';
import ServiceProvider from './pages/blog/ServiceProvider';

const pages = (
  <Route>
    {/* <Route path="/job" element={<Job />} /> */}
    <Route path="/jobs" element={<Jobs />} />
    <Route path="/" element={<Dashboard />} />
    <Route path="/job/:id" element={<Job />} />
    <Route path="/login" element={<Login />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/introduce" element={<Introduce />} />
    <Route path="/safeguard" element={<SafeGuard />} />
    <Route path="/serviceprovider" element={<ServiceProvider />} />
    <Route path="/about" element={<AboutUs />} />
    <Route path="/jobadd" element={<JobAdd />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/register" element={<Register />} />
    <Route path="/savedjobs" element={<SavedJobs />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/candidate" element={<Candidate />} />
    <Route path="/category/:id" element={<Category />} />
    <Route path="/searchresults" element={<SearchResults />} />
    <Route path="/appliedjobs" element={<AppliedJobs />} />
  </Route>
);

// const auth = (
//   <Route>
//     <Route path="/login" element={<Login />} />
//     <Route path="/register" element={<Register />} />
//     <Route path="/logout" element={<Logout />} />
//   </Route>
// );

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {pages}
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
