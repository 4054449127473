//Main components
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

//Project components
import Nav from "../components/Nav";
import Alert from "../components/Alert";
import Footer from "../components/Footer";
import Loader from "../components/Loader/Loader";
import {
    PHONE,
    TOKEN_KEY,
    JOB_APPLY,
    GET_JOB_LIST,
    SAVE_USER_JOB,
    GET_CATEGORIES,
    GET_SUBURB_LIST,
    REMOVE_SAVED_JOB,
    PATIK_RETURN_URL,
    GET_SAVED_USER_JOB,
    GET_ALL_USER_APPLIED_JOBS,
} from "../components/Constants";

function Jobs() {

    const [getJobs, setJobs] = useState('');
    const [getAlert, setAlert] = useState('');
    const [getSavedJobs, setSavedJobs] = useState([]);
    const [getAppliedJobs, setAppliedJobs] = useState('');
    const [getUserLoggedIn, setUserLoggedIn] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let user_logged_in = localStorage.getItem(PHONE);
        console.log("User logged " + user_logged_in);
        user_logged_in ? setUserLoggedIn(true) : setUserLoggedIn(false);

        //Get jobs
        axios.get(GET_JOB_LIST + "/" + user_logged_in)
            .then((response) => {
                setJobs(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

        if (user_logged_in) {
            axios.get(GET_ALL_USER_APPLIED_JOBS + "/" + user_logged_in)
                .then((response) => {
                    let jobs_applied_arr = [];
                    let jobs_applied = response.data;

                    for (let job in jobs_applied) {
                        jobs_applied_arr.push(jobs_applied[job]["job_id"]);
                        //console.log("Job " + JSON.stringify(jobs_applied[job]));
                    }
                    setAppliedJobs(jobs_applied_arr);
                    //console.log("Data " + JSON.stringify(jobs_applied_arr));
                })
                .catch((error) => {
                    console.error(error);
                })

            axios.get(GET_SAVED_USER_JOB + "/" + user_logged_in)
                .then((response) => {
                    let saved_jobs = [];
                    let saved_jobs_response = response.data;

                    for (let job in saved_jobs_response) {
                        saved_jobs.push(+saved_jobs_response[job]);
                    }

                    setSavedJobs(saved_jobs);
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, []);

    const iconTextStyle = {
        marginRight: "10px"
    };

    const applyJob = (id) => {
        if (getUserLoggedIn) {

            let user = localStorage.getItem(PHONE);

            axios.post(JOB_APPLY, {
                job_id: id,
                user: user
            }).then((response) => {
                //setJobApplied(true);
                setAlert({ "status": true, message: "Job successfully applied" });
            }).catch((error) => {
                setAlert({ "status": false, message: "Error applying for a job" });
                console.error(error);
            })
        } else {
            localStorage.setItem(PATIK_RETURN_URL, "/job/" + id);
            navigate("/login");
        }
    }

    const addToFav = (job_id) => {
        if (getUserLoggedIn) {
            let user = localStorage.getItem(PHONE);

            let save_job_url = SAVE_USER_JOB + "/" + user + "/" + job_id;
            axios.get(save_job_url)
                .then((response) => {
                    setAlert({ "status": true, message: "You have successfully saved job." });
                    navigate("/jobs");
                })
                .catch((error) => {
                    console.error(error);
                    setAlert({ "status": false, message: "Failed to save the job please try again." });
                })

        } else {
            localStorage.setItem(PATIK_RETURN_URL, "/jobs/");
            navigate("/login");
        }
    }

    const removeFromFav = (job_id) => {
        if (getUserLoggedIn) {
            let user = localStorage.getItem(PHONE);

            let save_job_url = REMOVE_SAVED_JOB + "/" + user + "/" + job_id;
            axios.get(save_job_url)
                .then((response) => {
                    setAlert({ "status": true, message: "You have successfully removed job from wishlist." });
                    navigate("/jobs");
                })
                .catch((error) => {
                    console.error(error);
                    setAlert({ "status": false, message: "Failed to remove job from wishlist." });
                })

        } else {
            localStorage.setItem(PATIK_RETURN_URL, "/jobs/");
            navigate("/login");
        }
    }

    return (
        <div>
            <Nav />

            <section className="page-title title-bg5" style={{ height: 260 }}>
                <div className="d-table">
                    <div className="d-table-cell">
                        <h2>Job Grid</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Job Grid</li>
                        </ul>
                    </div>
                </div>
                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </section>

            <section className="job-section jobs-grid-section pt-100 pb-70">
                <div className="container">
                    <div className="section-title text-center">
                        <h2>Jobs You May Be Interested In</h2>
                        {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>*/}
                    </div>

                    {getAlert ? <Alert message={getAlert.message} alertState={getAlert.status} /> : null}
                    <div className="row">
                        {getJobs ?
                            getJobs.map((job, index) => (
                                <div key={index} className="col-md-6">
                                    <div className="job-card">
                                        <div className="row align-items-center">
                                            <div className="col-lg-3">
                                                <div className="thumb-img">
                                                    <Link to={`/job/${job.id}`}>
                                                        <i class="zmdi zmdi-settings" style={{ fontSize: "60px", color: "#fd1616" }}></i>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="col-lg-6">
                                                <div className="job-info">
                                                    <h3>
                                                        <Link to={`/job/${job.id}`}>{job.title}</Link>
                                                    </h3>
                                                    <ul>
                                                        <li><a href="#">{job && job.suburb && job.suburb.suburb}</a></li>
                                                        <li>
                                                            <i style={iconTextStyle} class="zmdi zmdi-navigation"></i>
                                                            Zimbabwe
                                                        </li>
                                                        <li>
                                                            <i style={iconTextStyle} class="zmdi zmdi-apps"></i>
                                                            {job.category.name}
                                                        </li>
                                                        <li>
                                                            <i style={iconTextStyle} class="zmdi zmdi-play-for-work"></i>
                                                            Freelance
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="col-lg-3">
                                                <div className="job-save">
                                                    {getUserLoggedIn && getAppliedJobs.includes(job.id) ? <span style={{ backgroundColor: "#010c29", color: "white" }}>Applied</span> : <span style={{ cursor: "pointer" }} onClick={() => applyJob(job.id)}>Apply</span>}
                                                    {getUserLoggedIn && getSavedJobs.includes(job.id) ?
                                                        <a onClick={() => { removeFromFav(job.id) }}>
                                                            <i class="zmdi zmdi-favorite" style={{ fontSize: "28px", color: "#fd1616" }}></i>
                                                        </a>
                                                        :
                                                        <a onClick={() => { addToFav(job.id) }}>
                                                            <i class="zmdi zmdi-favorite-outline" style={{ fontSize: "28px", color: "#fd1616" }}></i>
                                                        </a>
                                                    }
                                                    <p>
                                                        <i style={iconTextStyle} class="zmdi zmdi-calendar"></i>
                                                        {job.created_at}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : <Loader />
                        }
                    </div>

                    {/*<nav aria-label="Page navigation example">
                        <ul className="pagination justify-content-center">
                            <li className="page-item disabled">
                                <a className="page-link" href="#" tabindex="-1" aria-disabled="true">
                                    <i className='bx bx-chevrons-left bx-fade-left'></i>
                                </a>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item"><a className="page-link active" href="#">2</a></li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#">
                                    <i className='bx bx-chevrons-right bx-fade-right'></i>
                                </a>
                            </li>
                        </ul>
                    </nav>*/}
                </div>
            </section>

            {/* <section className="subscribe-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <form className="newsletter-form" data-toggle="validator">
                                <input type="email" className="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button className="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" className="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}


            <Footer />
        </div>
    );
}

export default Jobs;