//Main components
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

//Project components
import {
    ROLE,
    PHONE,
    TOKEN_KEY,
    CREATE_USER,
    GET_SUBURB_LIST,
    PATIK_RETURN_URL,
} from "../../components/Constants";
import Nav from "../../components/Nav";
import Alert from "../../components/Alert";
import Footer from "../../components/Footer";

function Register() {

    const [getAlert, setAlert] = useState('');
    const [getLocations, setLocations] = useState([]);
    const [getFormError, setFormError] = useState({});
    const [getPasswordDontMatchError, setPasswordDontMatchError] = useState(false);
    const [getFormData, setFormData] = useState({
        name: '',
        email: '',
        user_role: '',
        phonenumber: '',
        referral_code: '',
        promotional_emails: '',
        password: '',
        verify_password: ''
    });

    const navigate = useNavigate();

    useEffect(() => {

        const token = localStorage.getItem(TOKEN_KEY);
        const return_url = localStorage.getItem(PATIK_RETURN_URL);

        if (token) {
            if (return_url)
                navigate("/" + return_url);
            else
                navigate("/dashboard");
        }

        axios.get(GET_SUBURB_LIST)
            .then((response) => {
                setLocations(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }, [])

    const formChange = (e) => {
        const { name, value } = e.target;
        const { password } = getFormData;

        if (name == "verify_password")
            password === value ? setPasswordDontMatchError(false) : setPasswordDontMatchError(true);

        setFormData({
            ...getFormData,
            [name]: value
        });
    };

    const formSubmit = (e) => {
        e.preventDefault();
        const { name, email, phonenumber, referral_code, promotional_emails, password } = getFormData;

        axios.post(CREATE_USER, {
            name: name,
            email: email,
            user_role: "serviceProvider",
            phonenumber: phonenumber,
            refered_by: referral_code,
            password: password,
            promotional_emails: promotional_emails,
        })
            .then((response) => {
                const response_data = response.data;
                localStorage.setItem(TOKEN_KEY, response_data.token);
                localStorage.setItem(ROLE, response.data.user_role);
                localStorage.setItem(PHONE, response.data.user.phonenumber);
                navigate("/dashboard");
            })
            .catch((error) => {
                //alert(JSON.stringify(error.response.status));

                if (error.response.status == 422) {
                    setFormError(error.response.data.errors);
                } else
                    setAlert("Failed to create user please contact admin");
            })
    };

    return (
        <div>

            <Nav />

            <section className="page-title title-bg13">
                <div className="d-table">
                    <div className="d-table-cell">
                        <h2>Sign Up</h2>
                        <ul>
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>Sign Up</li>
                        </ul>
                    </div>
                </div>
                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </section>

            <div className="signup-section ptb-100">
                <div className="container">
                    {getAlert ? <Alert alertState={false} message={getAlert} /> : null}
                    <div className="row">
                        <div className="col-lg-6 col-md-8 offset-md-2 offset-lg-3">
                            <form className="signup-form">
                                <div className="form-group">
                                    <label>Enter Full Name*</label>
                                    <input type="text" className="form-control" name="name" placeholder="Enter Name" onChange={formChange} required />
                                    {getFormError.name ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.name}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Enter Email</label>
                                    <input type="email" name="email" className="form-control" placeholder="Enter Your Email" onChange={formChange} required />
                                    {getFormError.email ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.email}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Enter Phone number*</label>
                                    <input type="text" name="phonenumber" className="form-control" placeholder="Enter Your Phone number" onChange={formChange} required />
                                    {getFormError.phonenumber ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.phonenumber}</span> : null}
                                </div>

                                {/* <div className="form-group">
                                    <label>Enter User Role*</label>
                                    <select className="form-control" name="user_role" onChange={formChange}>
                                        <option>Select role</option>
                                        <option value="customer">Customer</option>
                                        <option value="serviceProvider">Service provider</option>
                                    </select>
                                    {getFormError.user_role ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.user_role}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Current Suburb*</label>
                                    <select className="form-control" name="location" onChange={formChange}>
                                        <option>Select suburb</option>
                                        {getLocations ? getLocations.map((location, index) => (
                                            <option key={index} value={location.id}>{location.suburb}</option>
                                        ))
                                            : null}
                                    </select>
                                    {getFormError.location ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.location}</span> : null}
                                </div> */}

                                <div className="form-group">
                                    <label>Enter Referral Code</label>
                                    <input type="text" name="refferal_code" className="form-control" placeholder="Enter Referral Code is referred" onChange={formChange} />
                                </div>

                                <div className="form-group">
                                    <label>Enter Password*</label>
                                    <input type="password" name="password" className="form-control" placeholder="Enter Your Password" onChange={formChange} required />
                                    {getFormError.password ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.password}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Verify Password*</label>
                                    <input type="password" name="verify_password" className="form-control" placeholder="Verify Password" onChange={formChange} required />
                                    {getPasswordDontMatchError ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>Passwords to not match</span> : null}
                                </div>

                                <div className="form-group">
                                    <input type="checkbox" className="form-check-input" name="promotional_emails" onChange={formChange} />
                                    <label>Do you want to receive promotional emails</label>
                                </div>

                                <div className="signup-btn text-center">
                                    <button type="submit" onClick={formSubmit}>Sign Up</button>
                                </div>

                                <div className="other-signup text-center">
                                    <label style={{ fontSize: 11 }}>By clicking signin you agree to our <span style={{ color: "blue", cursor: "pointer" }} onClick={() => { navigate("/terms") }}>terms and conditions</span></label>
                                </div>

                                <div className="create-btn text-center">
                                    <p>
                                        Have an Account?
                                        <a onClick={() => navigate("/login")} style={{ cursor: "pointer" }}>
                                            Sign In
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section className="subscribe-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <form className="newsletter-form" data-toggle="validator">
                                <input type="email" className="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button className="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" className="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
}

export default Register;