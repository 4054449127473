//Main components
import React from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Contact() {

    const navigate = useNavigate();

    return (
        <div>
            <Nav />


            <section class="page-title title-bg23">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>Contact Us</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <div class="contact-card-section ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-10 offset-lg-1">
                            <div class="row">
                                <div class="col-md-4 col-sm-6">
                                    <div class="contact-card">
                                        <i class='bx bx-phone-call'></i>
                                        <ul>
                                            <li>
                                                <a href="tel:+263713555511">
                                                    +263 713 555 511
                                                </a>
                                            </li>
                                            <li>
                                                <a href="tel:263783333311">
                                                    +263 783 333 311
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-6">
                                    <div class="contact-card">
                                        <i class='bx bx-mail-send' ></i>
                                        <ul>
                                            <li>
                                                <a href="mailto:team@kotikoti.co.zw">
                                                    team@kotikoti.co.zw
                                                </a>
                                            </li>
                                            {/* <li>
                                                <a href="mailto:nigel@kotikoti.co.zw">
                                                    nigel@kotikoti.co.zw
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>

                                <div class="col-md-4 col-sm-6 offset-sm-3 offset-md-0">
                                    <div class="contact-card">
                                        <i class='bx bx-location-plus' ></i>
                                        <ul>
                                            <li>
                                                22 Clyde Road, Eastlea
                                            </li>
                                            <li>
                                                Harare, Zimbabwe
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="contact-form-section pb-100">
                <div class="container">
                    <div class="contact-area">
                        <h3>Lets' Talk With Us</h3>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Your Name" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Your Email" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="number" name="number" id="number" class="form-control" required data-error="Please enter your number" placeholder="Phone Number" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="subject" id="subject" class="form-control" required data-error="Please enter your subject" placeholder="Your Subject" />
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control message-field" id="message" cols="30" rows="7" required data-error="Please type your message" placeholder="Write Message"></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn contact-btn">
                                        Send Message
                                    </button>
                                    <div id="msgSubmit" class="h3 alert-text text-center hidden"></div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {/* <section class="subscribe-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <form class="newsletter-form" data-toggle="validator">
                                <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button class="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}


            <Footer />
        </div>
    );
}

export default Contact;