//Main components
import axios from "axios";
import React, { useState, useEffect } from "react";
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate, useParams, useLocation } from "react-router-dom";

//Project components
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import BuyCredit from "../components/BuyCredit";
import {
    USER,
    ROLE,
    PHONE,
    GET_USER,
    UPDATE_USER,
    MAKE_PAYMENT,
    GET_CATEGORIES,
    PAYMENT_STATUS,
    GET_SUBURB_LIST,
    DOWNLOAD_PDF_URL,
    PATIK_RETURN_URL,
    USER_QUALIFICATIONS,
} from "../components/Constants";
import Alert from "../components/Alert";
import Loader from "../components/Loader/Loader";

function Profile() {

    const [getUser, setUser] = useState('');
    const [getRole, setRole] = useState('');
    const [getAlert, setAlert] = useState("");
    const [getProfile, setProfile] = useState('');
    const [getSuburbs, setSuburbs] = useState('');
    const [getCategories, setCategories] = useState([]);
    const [paymentDialog, setPaymentDialog] = useState(false);
    const [getQualification, setQualification] = useState([]);
    const [servicesOffered, setServicesOffered] = useState([]);
    const [getQualificationName, setQualificationName] = useState([]);

    const [getFormData, setFormData] = useState({
        name: "",
        id_number: ""
    });

    let user = localStorage.getItem(PHONE);
    let userName = localStorage.getItem(USER);

    let navigate = useNavigate();
    // let { payment } = useParams();
    // const location = useLocation();

    // console.log("Payment " + payment);

    useEffect(() => {
        if (user) {

            setUser(user);
            let role = localStorage.getItem(ROLE);
            setRole(role);

            let get_user_endpoint = GET_USER + "/" + userName;
            axios.get(get_user_endpoint)
                .then((response) => {
                    setProfile(response.data);
                    console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.error(error);
                    setAlert({ status: false, message: "Failed to get profile info contact admin" });
                })

            //Check if it is a payment response
            let searchParams = new URLSearchParams(window.location.search);
            let payment_made = searchParams.get("payment");
            //let payment_made = "";
            console.log("Payment " + payment_made);
            if (payment_made) {
                axios.get(PAYMENT_STATUS + "/" + user)
                    .then((response) => {
                        setAlert({ status: true, message: "Payment has been made successful" });
                    })
                    .catch((error) => {
                        setAlert({ status: false, message: "No payment has been received." });
                    })
            }

            axios.get(GET_SUBURB_LIST)
                .then((response) => {
                    setSuburbs(response.data);
                })
                .catch((error) => {
                    console.error(error);
                })

            axios.get(GET_CATEGORIES)
                .then((response) => {
                    let categories = [];
                    const cat_data = response.data;

                    for (let cat_id in cat_data) {
                        let category = { cat: cat_data[cat_id]["id"], key: cat_data[cat_id]["name"] }
                        categories.push(category);
                    }
                    setCategories(categories);
                })
                .catch((error) => {
                    console.error(error);
                })
        } else {
            localStorage.setItem(PATIK_RETURN_URL, "/profile");
            navigate("/login");
        }
    }, [])

    const makePayment = () => {
        setPaymentDialog(true);
        // axios.post(MAKE_PAYMENT, {
        //     amount: 10,
        //     user: user,
        //     email: "nigel@kotikoti.co.zw"
        // })
        //     .then((response) => {
        //         window.location.href = response.data.url;
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //     })
    }

    const paymentDialogClose = () => {
        setPaymentDialog(false);
    }

    const addFile = () => {
        setQualificationName([...getQualificationName, '']);
    }

    const servicesOfferedSelect = (list, item) => {
        let servicesSelected = servicesOffered;
        servicesSelected.push(item.cat);
        setServicesOffered(servicesSelected);

        console.log(JSON.stringify(servicesOffered));
    }

    const servicesOfferedRemoved = (list, item) => {
        let new_services = [];
        for (let service_id in list) {
            let serviceLeft = list[service_id].cat;
            new_services.push(serviceLeft);
        }

        setServicesOffered(new_services);
    }

    const formChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const fileNameChange = (index, event) => {
        const values = [...getQualificationName];
        values[index] = event.target.value;
        setQualificationName(values);
    }

    const fileChange = (index, event) => {
        const values = [...getQualification];
        values[index] = event.target.files[0];
        setQualification(values);

        //console.log(getQualification);
    }

    const submitFormFiles = () => {
        const formData = new FormData();
        formData.append('user', user);

        // Assuming getQualificationName and getQualification are arrays of file names and files respectively
        getQualificationName.forEach((name, index) => {
            formData.append(`names[${index}]`, name);
        });

        getQualification.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });

        axios.post(USER_QUALIFICATIONS, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const formSubmit = () => {
        const { name, id_number } = getFormData;
        const services = servicesOffered;

        axios.post(UPDATE_USER, {
            user: user,
            name: name,
            id_number: id_number,
            services_offered: services
        })
            .then((response) => {
                setAlert({ status: true, message: "User profile successfully updated" });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    // const submitFormFiles = () => {
    //     axios.post(USER_QUALIFICATIONS, {
    //         user: user,
    //         names: getQualificationName,
    //         files: getQualification,
    //     },
    //         {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         })
    //         .then((response) => {
    //             console.log(response.data);
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         })
    // }

    // const fileChange = (event) => {
    //     //const values = [...getQualification];
    //     const values = event.target.files[0];
    //     console.log(values);
    // }

    return (
        <div>

            <Nav />

            <section class="page-title title-bg10">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>Profile</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Profile</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <section class="account-section ptb-100">
                <div class="container">

                    {getAlert ? <Alert alertState={getAlert.status} message={getAlert.message} /> : null}
                    {paymentDialog ? <BuyCredit closeModalBtn={paymentDialogClose} phone={getUser} /> : null}

                    <div class="row">
                        <div class="col-md-4">
                            <div class="account-information">
                                {getProfile ?
                                    <div class="profile-thumb">
                                        <i class="zmdi zmdi-account-circle" style={{ fontSize: 50 }}></i>
                                        <h3>{getProfile.name}</h3>
                                        <p>Web Developer</p>
                                        {getRole ? (getRole !== "serviceProvider" ?
                                            <div>
                                                <p style={{ marginTop: 20 }}>Current credits : {getProfile && getProfile.credit_balance ? getProfile.credit_balance : "0.00"}</p>
                                                <div class="account-details">
                                                    <form>
                                                        <button type="button" class="btn account-btn" onClick={makePayment}>Buy credits</button>
                                                    </form>
                                                </div>
                                            </div>
                                            : null)
                                            : null
                                        }
                                    </div>
                                    :
                                    <Loader />
                                }

                                {/* <ul>
                                    <li>
                                        <a href="#" class="active">
                                            <i class='bx bx-user'></i>
                                            My Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a href="resume.html">
                                            <i class='bx bxs-file-doc'></i>
                                            My Resume
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-briefcase'></i>
                                            Applied Job
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-envelope'></i>
                                            Messages
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-heart'></i>
                                            Saved Jobs
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-lock-alt' ></i>
                                            Change Password
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-coffee-togo'></i>
                                            Delete Account
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class='bx bx-log-out'></i>
                                            Log Out
                                        </a>
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div class="col-md-8">
                            {getProfile ?
                                <div class="account-details">
                                    <h3>Basic Information</h3>
                                    <form class="basic-info">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Name</label>
                                                    <input type="text" class="form-control" value={getProfile.name} onChange={formChange} placeholder="Name" />
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input type="email" class="form-control" value={getProfile.email} placeholder="Email" disabled />
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Phone</label>
                                                    <input type="text" class="form-control" value={getProfile.phonenumber} placeholder="Phone" disabled />
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Referral Code</label>
                                                    <input type="text" class="form-control" value={getProfile.referral_code} placeholder="Referral code" disabled />
                                                </div>
                                            </div>

                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label>Id Number</label>
                                                    <input type="text" class="form-control" value={getProfile.id_number} onChange={formChange} placeholder="Id number" />
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label>Select the areas of expertise</label>
                                                    <Multiselect
                                                        displayValue="key"
                                                        onKeyPressFn={function noRefCheck() { }}
                                                        onRemove={servicesOfferedRemoved}
                                                        onSearch={function noRefCheck() { }}
                                                        onSelect={servicesOfferedSelect}
                                                        style={{
                                                            chips: { background: "#fd1616" },
                                                            highlightOption: { background: "#fd1616" }
                                                        }}
                                                        options={getCategories}
                                                        selectedValues={getProfile.services_offered}
                                                    // selectedValueDecorator={function noRefCheck() { }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                    <h3>Address</h3>
                                    <form class="-candidate-address">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Country</label>
                                                    <input type="text" class="form-control" value={getProfile.country} placeholder="Country" />
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>City</label>
                                                    {/* <input type="text" class="form-control" value={getValue.suburb} placeholder="City" /> */}
                                                    {getSuburbs ?
                                                        <select className="form-control">
                                                            <option>Select suburb</option>
                                                            {getSuburbs.map((suburb, index) => (
                                                                <option value={suburb.suburb} key={index} selected={getProfile && (getProfile.suburb == suburb.suburb ? true : false)}>{suburb.suburb}</option>
                                                            ))}
                                                        </select>
                                                        : null
                                                    }
                                                </div>
                                            </div>

                                            {/* <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Region</label>
                                                    <input type="text" class="form-control" placeholder="Region" />
                                                </div>
                                            </div> */}
                                            {/* <div class="col-md-12">
                                                <button type="submit" class="account-btn">Edit</button>
                                                <button type="submit" class="account-btn">Save</button>
                                            </div> */}
                                        </div>
                                    </form>

                                    {getRole ?
                                        getRole !== "customer" ?
                                            <div>
                                                <h3>Other information</h3>
                                                <form class="cadidate-others">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Age</label>
                                                                <input type="number" class="form-control" placeholder="Age" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Work Experience</label>
                                                                <input type="number" class="form-control" placeholder="Work Experience" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Language</label>
                                                                <input type="text" class="form-control" placeholder="Language" />
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Skill</label>
                                                                <input type="text" class="form-control" placeholder="Skills" />
                                                            </div>
                                                        </div>
                                                        {/*<div class="col-md-12">
                                                            <button type="submit" class="account-btn">Edit</button>
                                                            <button type="submit" class="account-btn">Save</button>
                                                        </div>*/}
                                                    </div>
                                                </form>

                                                <h3>Social links</h3>
                                                <form class="candidates-sociak">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label>Facebook</label>
                                                                <input type="text" class="form-control" placeholder="www.facebook.com/user" />
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label>Twitter</label>
                                                                <input type="number" class="form-control" placeholder="www.twitter.com/user" />
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label>Linkedin</label>
                                                                <input type="text" class="form-control" placeholder="www.Linkedin.com/user" />
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-6">
                                                            <div class="form-group">
                                                                <label>Instagram</label>
                                                                <input type="text" class="form-control" placeholder="www.instagram.com/user" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <button type="submit" class="account-btn">Edit</button>
                                                            <button type="button" onClick={formSubmit} class="account-btn">Save</button>

                                                        </div>
                                                    </div>
                                                </form>

                                                <h3>Experience attachment documents</h3>
                                                <form>
                                                    <div class="row">
                                                        <div>
                                                            {getProfile.files ?
                                                                getProfile.files.map((file, index) => (
                                                                    <a
                                                                        key={index}
                                                                        target="_blank"
                                                                        className="col-lg-3"
                                                                        style={{ marginLeft: 20 }}
                                                                        href={DOWNLOAD_PDF_URL + file.meta_value}
                                                                    >
                                                                        <i class="zmdi zmdi-collection-pdf"></i>
                                                                        <label>{index + 1}. Download Qualification</label>
                                                                    </a>
                                                                ))
                                                                : null
                                                            }
                                                        </div>
                                                        <button type="button" class="account-btn" onClick={addFile}>+Add qualification</button>
                                                        {getQualificationName ?
                                                            getQualificationName.map((file, index) => (
                                                                <div class="col-lg-12" key={index} style={{ marginTop: 20 }}>
                                                                    {/* <label for="formFile" class="form-label">{file}</label> */}
                                                                    <input type="text" className="form-control" value={file} placeholder="Enter qualification name" onChange={(e) => fileNameChange(index, e)} />
                                                                    <input
                                                                        class="form-control"
                                                                        type="file"
                                                                        id="formFile"
                                                                        onChange={(e) => fileChange(index, e)}
                                                                    />
                                                                </div>
                                                            ))
                                                            : null
                                                        }

                                                        {/* <input
                                                            class="form-control"
                                                            type="file"
                                                            id="formFile"
                                                            onChange={fileChange}
                                                        /> */}

                                                        <div class="col-md-12">
                                                            {/* <button type="submit" class="account-btn">Edit</button> */}
                                                            <button type="button" class="account-btn" onClick={submitFormFiles}>Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            : null
                                        : null
                                    }
                                </div>
                                : <Loader />
                            }
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="subscribe-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <form class="newsletter-form" data-toggle="validator">
                                <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button class="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
}

export default Profile;

