//Main components
import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

//Project components
import Nav from "../components/Nav";
import Alert from "../components/Alert";
import Footer from "../components/Footer";
import PhoneVerify from "../components/PhoneVerify";
import {
    PHONE,
    TOKEN_KEY,
    JOB_CREATE,
    CREATE_USER,
    PATIK_SEARCH,
    VERIFY_PHONE,
    PATIK_SUBURB,
    GET_CATEGORIES,
    PATIK_CATEGORY,
    GET_SUBURB_LIST,
} from "../components/Constants";

function JobAdd() {

    const [getAlert, setAlert] = useState(false);
    const [getSuburbs, setSuburbs] = useState('');
    const [getFormError, setFormError] = useState({});
    const [getJobSearch, setJobSearch] = useState({});
    const [getCategories, setCategories] = useState('');
    const [getAlertMessage, setAlertMessage] = useState('');
    const [getPhoneVerify, setPhoneVerify] = useState(false);
    const [getFormData, setFormData] = useState({
        name: '',
        title: '',
        category: '',
        location: '',
        job_status: '',
        job_source: '',
        min_budget: '',
        max_budget: '',
        start_date: '',
        phonenumber: '',
        contact_method: '',
        job_description: '',
        job_requirements: '',
    });

    useEffect(() => {

        const job_searchs = localStorage.getItem(PATIK_SEARCH);
        const job_suburbs = localStorage.getItem(PATIK_SUBURB);
        const job_categorys = localStorage.getItem(PATIK_CATEGORY);

        setFormData({ title: job_searchs, location: job_suburbs, category: job_categorys });

        setJobSearch({ job_search: job_searchs, job_suburb: job_suburbs, job_category: job_categorys });

        axios.get(GET_CATEGORIES)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

        axios.get(GET_SUBURB_LIST)
            .then((response) => {
                setSuburbs(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

    }, []);

    const navigate = useNavigate();

    const formChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const submitFormData = (e) => {
        e.preventDefault();
        const { name, title, category, location, min_budget, max_budget, start_date, phonenumber, job_status, job_source, contact_method, job_description, job_requirements } = getFormData;

        //First create a user, then create a job, then verify the phonenumber
        axios.post(CREATE_USER, {
            name: name,
            user_role: "customer",
            phonenumber: phonenumber,
        })
            .then((response) => {
                axios.post(JOB_CREATE, {
                    name: name,
                    title: title,
                    category: category,
                    location: location,
                    job_status: job_status,
                    job_source: job_source,
                    min_budget: min_budget,
                    max_budget: max_budget,
                    start_date: start_date,
                    phonenumber: phonenumber,
                    description: job_description,
                    contact_method: contact_method,
                    requirements: job_requirements,
                })
                    .then((response) => {
                        axios.post(VERIFY_PHONE, {
                            phonenumber: phonenumber
                        }).then((response) => {
                            setPhoneVerify(true);
                        }).catch((error) => {
                            console.error(error);
                        })
                    })
                    .catch((error) => {
                        console.error(error);
                        //alert(JSON.stringify(error));
                        if (error.response.status == 422) {
                            setFormError(error.response.data.errors)
                        }
                    })

            })
            .catch((error) => {
                console.error(error);
                if (error.response.status == 422) {
                    setFormError(error.response.data.errors)
                }
            })
    }

    const closePhoneModal = () => {
        setPhoneVerify(false);
    }

    const setAlertCtrl = (alert) => {
        setAlert(alert);
    }

    const setAlertMessageCtrl = (msg) => {
        setAlertMessage(msg);
    }

    return (
        <div>
            <Nav />

            <section className="page-title title-bg12">
                <div className="d-table">
                    <div className="d-table-cell">
                        <h2>What are you looking for</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>What are you looking for</li>
                        </ul>
                    </div>
                </div>
                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </section>

            <div className="signin-section ptb-100">
                <div className="container">

                    {getPhoneVerify ? <PhoneVerify closeModalBtn={closePhoneModal} setAlert={setAlertCtrl} setAlertMessage={setAlertMessageCtrl} phone={getFormData.phonenumber} /> : null}
                    {getAlert ? <Alert alertState={getAlert} message={getAlertMessage} /> : null}
                    <div className="row">
                        <div className="col-lg-6 col-md-8 offset-md-2 offset-lg-3">
                            <form className="signin-form">

                                <div className="form-group">
                                    <label>Full Name</label>
                                    <input type="text" name="name" className="form-control" placeholder="Enter your name" onChange={formChange} required />
                                    {getFormError.title ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.title}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>What kind of work do you need?</label>
                                    <input type="text" name="title" className="form-control" value={getFormData.title ? getFormData.title : null} placeholder="Enter Your Job title" onChange={formChange} required />
                                    {getFormError.title ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.title}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Category</label>
                                    {/* <input type="text" name="title" className="form-control" value={getJobSearch ? getJobSearch.job_search : null} placeholder="Enter Your Job title" onChange={formChange} required /> */}
                                    <select className="form-control" onChange={formChange} name="category">
                                        <option>Choose option</option>
                                        {getCategories ?
                                            getCategories.map((category, index) => (
                                                <option key={index} value={category.id} selected={getFormData.category == category.name ? true : false}>{category.name}</option>
                                            ))
                                            : null
                                        }
                                    </select>
                                    {getFormError.category ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.category}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Where do you need these services</label>
                                    {/* <input type="text" name="title" className="form-control" value={getJobSearch ? getJobSearch.job_search : null} placeholder="Enter Your Job title" onChange={formChange} required /> */}
                                    <select className="form-control" onChange={formChange} name="location">
                                        <option>Choose option</option>
                                        {getSuburbs ?
                                            getSuburbs.map((suburb, index) => (
                                                <option key={index} value={suburb.id} selected={getFormData.location == suburb.suburb ? true : false}>{suburb.suburb}</option>
                                            ))
                                            : null
                                        }
                                    </select>
                                    {getFormError.location ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.location}</span> : null}
                                </div>

                                {/**TODO set the job status to database, controller and the options page */}
                                <div className="form-group">
                                    <label>Job status</label>
                                    <select className="form-control" name="job_status" onChange={formChange}>
                                        <option>Choose option</option>
                                        <option value="ready to hire">Ready to hire</option>
                                        <option value="hire based on quotations">Hire, based on quotations</option>
                                        <option value="planning and budgeting">Planning and budgeting</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Please describe what you're looking for in a few sentences</label>
                                    <textarea className="form-control" onChange={formChange} name="job_description"></textarea>
                                    {getFormError.description ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.description}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Job requirements</label>
                                    <textarea className="form-control" onChange={formChange} name="job_requirements"></textarea>
                                    {getFormError.requirements ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.requirements}</span> : null}
                                </div>

                                <div className="row">
                                    <label>Budget Range</label>

                                    <div className="form-group col-6">
                                        <label>Minimum value</label>
                                        <input type="number" step="0.01" name="min_budget" className="form-control" onChange={formChange} />
                                        {getFormError.min_budget ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.min_budget}</span> : null}
                                    </div>

                                    <div className="form-group col-6">
                                        <label>Maximum value</label>
                                        <input type="number" step="0.01" name="max_budget" className="form-control" onChange={formChange} />
                                        {getFormError.max_budget ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.max_budget}</span> : null}
                                    </div>
                                </div>

                                {/* <div className="form-group">
                                    <label>Estimated start date</label>
                                    <input type="date" name="start_date" className="form-control" onChange={formChange} />
                                    {getFormError.start_date ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.start_date}</span> : null}
                                </div> */}

                                <div className="form-group">
                                    <label>When do you need these services</label>
                                    <select className="form-control" name="job_status">
                                        <option>Select options</option>
                                        <option value="">Am flexible</option>
                                        <option value="">In the next few days</option>
                                        <option value="">As soon as possible</option>
                                        <option value="">On a set date</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Where did you hear about us?</label>
                                    <select className="form-control" name="job_source" multiple>
                                        <option value="">Referral from a friend</option>
                                        <option value="">Facebook</option>
                                        <option value="">Linkedin</option>
                                        <option value="">Instagram</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>How should the professional contact you</label>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <input type="checkbox" className="form-check-input" name="contact_method" id="whatsapp" value="WhatsApp" />
                                            <label for="whatsapp" className="form-check-label" style={{ marginLeft: 15 }}>WhatsApp</label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="checkbox" className="form-check-input" name="contact_method" id="call" value="Call" />
                                            <label for="call" className="form-check-label" style={{ marginLeft: 15 }}>Call</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-3">
                                        <label>Country code</label>
                                        <input type="text" name="min_budget" className="form-control" value="+263" disabled />
                                    </div>
                                    <div className="form-group col-9">
                                        <label>Verify phonenumber</label>
                                        <input type="text" name="phonenumber" className="form-control" placeholder="Enter Your phone number" onChange={formChange} required />
                                        {getFormError.phonenumber ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.phonenumber}</span> : null}
                                    </div>
                                </div>

                                <div className="signin-btn text-center">
                                    <button type="submit" onClick={submitFormData}>Post job</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section className="subscribe-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <form className="newsletter-form" data-toggle="validator">
                                <input type="email" className="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button className="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" className="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}


            <Footer />
        </div>
    );
}

export default JobAdd;