const DOMAIN = "https://backend.kotikoti.co.zw/";
//const DOMAIN = "http://localhost:8000/";

export const ROLE = "patik-role";
export const PHONE = "patik-phone";
export const USER = "patik-user";
export const NAME = "patik-name";
export const DOWNLOAD_PDF_URL = DOMAIN + "storage/";
export const PRIMARY_COLOR = "#ef7f1b";
export const TOKEN_KEY = "patik-auth-key";
export const PATIK_SEARCH = "patik-search";
export const PATIK_SUBURB = "patik-suburb";
export const PATIK_CATEGORY = "patik-category";
export const PATIK_RETURN_URL = "patik-return-url";

//API endpoints
export const GET_JOB = DOMAIN + "api/job";
export const GET_USER = DOMAIN + "api/user";
export const JOB_APPLY = DOMAIN + "api/job/apply";
export const MAKE_PAYMENT = DOMAIN + "api/payment";
export const GET_JOB_LIST = DOMAIN + "api/jobs/get";
export const JOB_CREATE = DOMAIN + "api/jobs/create";
export const GET_SUBURB_LIST = DOMAIN + "api/suburbs";
export const GET_CATEGORY = DOMAIN + "api/jobs/category";
export const VERIFY_PHONE = DOMAIN + "api/users/verifyPhone";
export const JOB_APPLY_CHECK = DOMAIN + "api/job/applycheck";
export const GET_CATEGORIES = DOMAIN + "api/jobs/categories";
export const GET_JOBS_CATEGORY = DOMAIN + "api/jobs/category";
export const GET_JOBS_FROM_SEARCH = DOMAIN + "api/jobs/search";
export const USER_QUALIFICATIONS = DOMAIN + "api/user/qualifications";
export const GET_ALL_USER_APPLIED_JOBS = DOMAIN + "api/job/applications";
export const CREATE_VERIFY_PHONE = DOMAIN + "api/users/createVerifyPhone";

export const FORGET_PASSWORD = DOMAIN + "api/forgotpassword";
export const PAYMENT_STATUS = DOMAIN + "api/payment/status";

export const LOGIN_USER = DOMAIN + "api/users/login";
export const CREATE_USER = DOMAIN + "api/users/create";
export const UPDATE_USER = DOMAIN + "api/users/updateUser";
export const SAVE_USER_JOB = DOMAIN + "api/user/savedjobs";
export const GET_JOBS_APPLIED = DOMAIN + "api/jobs/applied";
export const GET_SAVED_JOBS = DOMAIN + "api/user/saved/jobs";
export const GET_SAVED_USER_JOB = DOMAIN + "api/user/getsavedjobs";
export const REMOVE_SAVED_JOB = DOMAIN + "api/user/removesavedjobs";