//Main components
import React from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import BoardImg from "../components/images/board.png";

function Terms() {

    const navigate = useNavigate();

    return (
        <div>

            <Nav />

            <section class="page-title title-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>Terms and conditions</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Terms and conditions</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <section class="about-section ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="about-text">
                                <div class="section-title">
                                    <h2 style={{ textDecoration: "underline" }}>INTRODUCTION</h2>
                                </div>
                                <p>
                                    Thank you for using Koti Koti!
                                    These Terms of Service <span style={{ fontWeight: "bold" }}>(“Terms”)</span> are a binding legal agreement between you and <span style={{ fontWeight: "bold" }}>Koti Koti</span> that govern your right to use the websites from <span style={{ fontWeight: "bold" }}>Koti Koti</span>. When used in these Terms, “Koti Koti,” “we,” “us,” or “our” refers to the Koti Koti entity whom you are contracting.
                                </p>
                                <p>
                                    Welcome to the Koti Koti Global PBC website. This website is published by or on behalf of Koti Koti Global PBC a company registered in Zimbabwe whose registered office at 22 Clyde Road Eastlea Harare.
                                </p>
                                <p>
                                    By visiting this website, you agree to be bound by the following terms and conditions (“Terms”) and our privacy policy. These Terms of Service (“Terms”) are a binding legal agreement between you and Koti Koti that govern your right to use the websites from Koti Koti. When used in these Terms, “Koti Koti,” “we,” “us,” or “our” refers to the Koti Koti entity whom you are contracting.
                                </p>
                                <p>
                                    If you have any queries about the website or these Terms, you can contact us by any of the means set out in paragraph 10 of these Terms. If you do not agree with these Terms, you should stop using the website immediately.
                                </p>

                                <h4 style={{ textDecoration: "underline" }}>GENERAL RULES RELATING TO CONDUCT</h4>
                                <p>
                                    The website is made available for your own, personal use. The website must not be used for any commercial purpose whatsoever or for any illegal or unauthorised purpose. When you use the website you must comply with all applicable Zimbabwe laws and with any applicable international laws, including the local laws in your country of residence (together referred to as “Applicable Laws”).
                                </p>

                                <p>
                                    You agree that when using the website, you will comply with all Applicable Laws and these Terms. In particular, but without limitation, you agree not to:
                                    Use the website in any unlawful manner or in a manner which promotes or encourages illegal activity including (without limitation) copyright infringement; or
                                    Attempt to gain unauthorized access to the website or any networks, servers or computer systems connected to the website; or
                                </p>
                                <p>
                                    Modify, adapt, translate or reverse engineer any part of the website or re-¬format or frame any portion of the pages comprising the website, save to the extent expressly permitted by these Terms or by law.
                                </p>
                                <p>
                                    You agree to indemnify Koti Koti Global PBC in full and on demand from and against any loss, damage, costs or expenses which they suffer or incur directly or indirectly as a result of your use of the website otherwise than in accordance with these Terms or Applicable Laws.
                                </p>

                                <h4 style={{ textDecoration: "underline" }}>PRIVACY POLICY</h4>
                                <p>
                                    We take your privacy very seriously. Koti Koti will only use your personal information in accordance with the terms of our privacy policy. By using the website you acknowledge and agree that you have read and accept the terms of our privacy policy and these Terms.
                                </p>

                                <h4 style={{ textDecoration: "underline" }}>DISCLAIMER / LIABILITY</h4>
                                <p>
                                    Use of the website is at your own risk. The website is provided on an “as is” basis. To the maximum extent permitted by law:
                                </p>
                                <p>
                                    Koti Koti disclaims all liability whatsoever, whether arising in contract, tort (including negligence) or otherwise in relation to the website.
                                </p>
                                <p>
                                    All implied warranties, terms and conditions relating to the website (whether implied by statue, common law or otherwise), including (without limitation) any warranty, term or condition as to accuracy, completeness, satisfactory quality, performance, fitness for purpose or any special purpose, availability, non--infringement, information accuracy, interoperability, quiet enjoyment and title are, as between Koti Koti and you, hereby excluded. In particular, but without prejudice to the foregoing, we accept no responsibility for any technical failure of the internet and/or the website; or any damage or injury to users or their equipment as a result of or relating to their use of the website. Your statutory rights are not affected.
                                </p>
                                <p>
                                    Koti Koti will not be liable, in contract, tort (including, without limitation, negligence), under statute or otherwise, as a result of or in connection with the website, for any:
                                </p>
                                <p>
                                    Economic loss (including, without limitation, loss of revenues, profits, contracts, business or anticipated savings).
                                    Loss of goodwill or reputation.
                                    Special or indirect or consequential loss.
                                    Nothing in these Terms shall be construed as excluding or limiting the liability of Koti Koti for death or personal injury caused by its negligence or for any other liability which cannot be excluded by the Laws of the Republic of Zimbabwe.

                                </p>

                                <h4 style={{ textDecoration: "underline" }}>SERVICE SUSPENSION</h4>
                                <p>
                                    Koti Koti reserves the right to suspend or cease providing any services relating to the website with or without notice, and shall have no liability or responsibility to you in any manner whatsoever if it chooses to do so.
                                </p>

                                <h6>General</h6>
                                <p>
                                    These Terms (as amended from time to time) constitute the entire agreement between you and Koti Koti concerning your use of the website.
                                </p>
                                <p>
                                    Koti Koti reserves the right to update these Terms from time to time. If it does so, the updated version will be effective immediately, and the current Terms are available through a link on this page. You are responsible for regularly reviewing these Terms so that you are aware of any changes to them and you will be bound by the new policy upon your continued use of the website. No other variation to these Terms shall be effective unless in writing and signed by an authorized representative on behalf of Koti Koti.
                                </p>
                                <p>
                                    These Terms shall be governed by and construed in accordance with the Laws of the Republic of Zimbabwe and you agree to submit to the exclusive jurisdiction of the Courts of Republic of Zimbabwe.
                                </p>
                                <p>
                                    If any provision(s) of these Terms is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed, as nearly as possible, to reflect the intentions of the parties (as reflected in the provision(s)) and all other provisions shall remain in full force and effect.
                                </p>

                                <h4 style={{ textDecoration: "underline" }}>PRIVACY POLICY</h4>
                                <p>
                                    Introduction: This policy covers the processing of personal data by Koti Koti whose registered address is at 22 Clyde Road, Eastlea, Harare. Koti Koti is committed to protecting the privacy of your personal information whilst striving to provide the very best user experience. We want our websites to be safe and enjoyable for everyone. Under the laws of Republic of Zimbabwe and related laws, we have a legal duty to protect the personal information we collect from you.
                                    If you have any concerns, questions or comments about this privacy policy or the website you can contact us using the contact details in paragraph 12 of this policy.

                                </p>
                                <p>
                                    Maintenance and storage of information: Your information is collected and stored on servers which are owned and maintained by or on behalf of Koti Koti. We may keep your information for a reasonable period for the purposes set out in this privacy policy.
                                </p>
                                <p>
                                    Security: In accordance with our requirements under the Law of the Republic of Zimbabwe, we will adopt appropriate security procedures to help prevent unauthorised access to your information. Koti Koti shall not be liable for any attempt to hack or crack or otherwise gain access to any part of this website including any of your information.
                                </p>
                                <p>
                                    Policy changes: By using this website you agree to the terms of this privacy policy and our terms of use. We may amend this privacy policy and the terms of use from time to time. If we do so, we will post an updated version on this website. You will be bound by the new terms upon your continued use of this website.
                                </p>
                                <p>
                                    The Koti Koti Platform offers an online venue that enables users (“Members”) to publish, offer, search for, and book services. Members who publish and offer services are “Contractors” and Members who search for, book, or use services are “Clients.” Contractors offer services. You must register an account to access and use many features of the Koti Koti Platform, and must keep your account information accurate. As the provider of the Koti koti Platform, Koti Koti does not own, control, offer or manage any Listings or Contractors Services. Koti Koti is not a party to the contracts entered into directly between Contractors and Clients, nor is Koti Koti a service provider. Koti Koti is not acting as an agent in any capacity for any Member, except as specified in the Payments Terms of Service (“Payment Terms”).
                                </p>
                                <p>
                                    The Koti Koti Platform offers an online venue that enables users (“Members”) to publish, offer, search for, and book services. Members who publish and offer services are “Contractors” and Members who search for, book, or use services are “Clients.” Contractors offer services. You must register an account to access and use many features of the Koti Koti Platform, and must keep your account information accurate. As the provider of the Koti koti Platform, Koti Koti does not own, control, offer or manage any Listings or Contractors Services. Koti Koti is not a party to the contracts entered into directly between Contractors and Clients, nor is Koti Koti a service provider. Koti Koti is not acting as an agent in any capacity for any Member, except as specified in the Payments Terms of Service (“Payment Terms”).
                                </p>

                                <h6>CONFIDENTIALITY:</h6>
                                <p>
                                    Personal information or business information that you supply to Koti Koti through the site, other than information that is in the public domain, will be treated confidentially and in line with our Privacy and Cookie Policy. Confidential information will not be disclosed to any third party, including for the purposes of marketing, without your prior permission. We will only disclose your information if it is necessary for the performance of our services or where so required by law.
                                </p>

                                <h6>USER RIGHTS OF ACCESS </h6>
                                <p>
                                    You have permission for temporary, non-exclusive use of the site. Koti Koti reserves the right to withdraw or change the content of the site and these terms and conditions at any time without notifying you and without having any legal responsibility towards you.
                                    You are not allowed to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any part of the site.

                                </p>
                                <p>
                                    You are also not allowed to display, disclose, or otherwise commercially exploit, or otherwise make the site and/or any documents or online resources the site available to any third party.
                                </p>
                                <p>
                                    You are responsible for configuring your own computer and software to access the material and content on our site including downloadable files. We do not warrant that the way we deliver data to you is compatible with your software or with the way your mobile device, computer or tablet is configured. Although we do our best to protect our site, we are not responsible for any viruses, bugs or similar issues. We advise that you use your own virus protection software to protect yourself.
                                </p>
                                <p>
                                    You must treat all identification codes, passwords and other security information that you obtain from use of the site and (where applicable) for you to access parts of the site, as confidential. If we think you have failed to keep confidentiality, we may disable any such information, including your passwords and other codes.
                                </p>
                                <p>
                                    You agree to follow our Acceptable Use Policy (see section below).
                                    If you allow anyone else to use our site, you must make sure that they read these terms and conditions first, and that they agree to and follow them.
                                    If you do not use the site according to the law and these terms and conditions, we may suspend your usage, or stop it completely.
                                    We frequently update the site and make changes to it, but we have no duty to do this. This means that content on the site may be outdated or incorrect. We will have no liability to you for any losses or damage you may suffer from your reliance on any of our materials.
                                </p>
                                <p>
                                    We do not save your credit card details or share customer details with any third parties and we follow our Privacy and Cookie Policy in handling information about you. By using the site, you agree to us handling this information and confirm that data you provide is accurate.
                                    Our site uses cookies. More information is provided in our Privacy and Cookie Policy.

                                </p>

                                <h4 style={{ textDecoration: "underline" }}>USE OF OUR SITE</h4>
                                <ul>
                                    <li>You agree not to use this site for any of the following purposes:</li>
                                    <li>to break any laws or regulations;</li>
                                    <li>to do anything fraudulent, or which has a fraudulent effect;</li>
                                    <li>to harm or attempt to harm minors;</li>
                                    <li>to do anything with material that does not meet our content standards (these are listed below);</li>
                                    <li>to copy in any way or re-sell any part of our site;</li>
                                    <li>to commercially exploit any information or property listings appearing on our site;</li>
                                    <li>to interfere with or damage any part of our site, equipment, network, software or storage arrangements;</li>
                                    <li>for unsolicited advertising material (known as spam);</li>
                                    <li>to transmit any data or material that is harmful to other programs, software, or hardware.</li>
                                </ul>

                                <h4 style={{ textDecoration: "underline" }}>SUSPENSION AND TERMINATION</h4>
                                <p>
                                    If we believe you are in breach of our Acceptable Use Policy, we will take whatever steps we think are necessary to address this, including stopping your use of the site temporarily or permanently, removing material you have put on the site or any of our social media groups, sending you a formal warning, taking legal action and / or telling the relevant authorities.
                                    We will not be held liable for any of your costs arising from any actions we take to deal with any breach of this policy.

                                </p>
                            </div>
                        </div>
                        {/* <div class="col-lg-6">
                            <div class="about-img">
                                <img src={BoardImg} alt="about image" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Terms;

