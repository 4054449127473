//Main components
import React from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import BoardImg from "../../components/images/board.png";

function ServiceProvider() {

    const navigate = useNavigate();

    return (
        <div>

            <Nav />

            <section class="page-title title-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>Choosing a service provider</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Choosing a service provider</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <section class="about-section ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="about-text">
                                <div class="section-title">
                                    <h2>Choosing the right service provider is a complex decision that requires various
                                        considerations about your business, customers, unique needs, and budget.</h2>
                                </div>

                                <p>
                                    Even if the managers had the technical skills to attend to all the needs of their facilities,
                                    outsourcing saves time, money, and hassle. However, the decision process can be
                                    overwhelming with the proliferation of <span style={{ color: "red" }}>managed services providers (MSP).</span>
                                </p>
                                <p>
                                    When choosing the <span style={{ color: "red" }}>right service providers</span> can be so vital to a facility’s success,
                                    sustainability, and security, hereafter are the top five things to consider when choosing
                                    one.
                                </p>
                                <h4>1. Reputation</h4>
                                <p>
                                    18th century US visionary Benjamin Franklin famously said, “It takes many good deeds
                                    to build a good reputation, and only one bad one to lose it.”
                                    When narrowing down the options for outsourcing some tasks of your business to a
                                    service provider, going by reputation is one of the easiest ways to achieve this.
                                    A reputable business partner’s track record gives you confidence. It’s possible to verify
                                    this by not only online reviews but also by talking to the company’s associates and any
                                    current or former customers.
                                </p>

                                <h4>2. Technical Expertise and Capacity</h4>
                                <p>
                                    Any outsourced technical services should add value to your business, which means
                                    they should have the profound and specialized skill sets and technical capabilities that
                                    you don’t have in-house.
                                    Thus, while choosing a service provider, facility managers must get a satisfactory
                                    answer to questions including: What is the size of the technical fleet? What’s the
                                    company’s bandwidth and capacity for support — both with <span style={{ color: "red" }}>routine jobs </span>and
                                    emergencies? Do they carry van stock?
                                </p>

                                <h4>3. Account Management</h4>
                                <p>
                                    A reliable and transparent set of transactions between you and your service provider is
                                    crucial for fruitful business collaboration.<span style={{ color: "red" }}>An account manager</span>in your partner company
                                    is the gateway to safeguard a smooth experience and make the most out of the services
                                    provided.
                                    An ideal MSP must be competent in the account management department. The right
                                    partner as an account manager is an invaluable business ally: They can provide
                                    industry updates, act as an escalator for emergencies, and keep making meaningful
                                    recommendations about the industry or the products.
                                </p>


                                <h4>4. Training, Safety, Equipment</h4>
                                <p>
                                    Thanks to the rapid digitization process in the arena of facility and building
                                    management, most digital tools benefit from innovations, improvements, and updates.
                                    This means the skill sets and equipment of those responsible for them must also
                                    keep <span style={{ color: "red" }}>continuously upgraded</span>to ensure the best service and safety.
                                    Thus, when looking for an MSP, facility managers must get satisfactory answers to
                                    questions including: Is the service provider invested in up-to-date training of their staff?
                                    Are they proactive about safety, and providing any necessary updates, and performing
                                    checks? Are the technical products or equipment they’re using cutting-edge and well-
                                    maintained?
                                </p>

                                <h4>5. Cost</h4>
                                <p>
                                    Cost is one of the single most important considerations of any commercial activity. Like
                                    many other effective business strategies, outsourcing technical services to a business
                                    partner has the <span style={{ color: "red" }}>positive outcome of reducing costs.</span>
                                    However, for optimal results and the best commercial outcomes, cheap doesn’t always
                                    bring cheerful results. If a service or a product sounds too good to be true, it probably is:
                                    Using them might result in interrupted services and unhappy customers, causing the
                                    business to lose money in the long run.
                                    Hence, facility managers looking for service providers are recommended to inquire
                                    about what costs will entail, what will be included in services, and any potential perks.
                                    For instance, many MSPs will offer a more favorable rate for long-term collaborations.
                                </p>

                                <h4>6. The Right Partners to Champion Your Needs</h4>
                                <p>
                                    Choosing the right service provider is a complex decision that requires various
                                    considerations about your business, customers, unique needs, and budget.
                                    However, it’s also a decision that might have a deep impact on your business’s core
                                    aspects, such as safety and security, and customer satisfaction — so, make it count.
                                </p>
                            </div>
                        </div>
                        {/* <div class="col-lg-6">
                            <div class="about-img">
                                <img src={BoardImg} alt="about image" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <section class="use-section pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Easiest Way To Use</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>1</span>
                                <i class='flaticon-website'></i>
                                <h3>Browse Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>2</span>
                                <i class='flaticon-recruitment'></i>
                                <h3>Find Your Vaccancy</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="use-text">
                                <span>3</span>
                                <i class='flaticon-login'></i>
                                <h3>Submit Resume</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="why-choose-two pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Why You Choose Us Among Other Job Site?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-resume"></i>
                                <h3>Advertise Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-recruitment"></i>
                                <h3>Recruiter Profiles</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="choose-card">
                                <i class="flaticon-employee"></i>
                                <h3>Find Your Dream Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="grow-business pb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="grow-text">
                                <div class="section-title">
                                    <h2>Grow Your Business Faster With Premium Advertising</h2>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                                </p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit.
                                </p>

                                <div class="theme-btn">
                                    <a href="#" class="default-btn">Checkout More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="grow-img">
                                <img src="assets/img/grow-img.jpg" alt="grow image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="counter-section pt-100 pb-70">
                <div class="container">
                    <div class="row counter-area">
                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-resume"></i>
                                <h2><span>1225</span></h2>
                                <p>Job Posted</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-recruitment"></i>
                                <h2><span>145</span></h2>
                                <p>Job Filed</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-portfolio"></i>
                                <h2><span>170</span></h2>
                                <p>Company</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-employee"></i>
                                <h2><span>125</span></h2>
                                <p>Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-style-two ptb-100">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>What Client’s Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="row">
                        <div class="testimonial-slider-two owl-carousel owl-theme">
                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Alisa Meair</h3>
                                    <p>CEO of  Company</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Adam Smith</h3>
                                    <p>Web Developer</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>John Doe</h3>
                                    <p>Graphics Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="blog-section pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>News, Tips & Articles</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/1.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            How to Indroduce in Yourself in Job Interview?
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/2.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            Looking for Highly Motivated Product to Build
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/3.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            The Reason Why Software Develope is the Best Job
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
}

export default ServiceProvider;

