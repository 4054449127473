//Core components
import axios from "axios";
import React, { useState } from "react";

//Global constants
import {
    TOKEN,
    FORGET_PASSWORD,
    CREATE_VERIFY_PHONE
} from "./Constants";


function ForgotPassword(props) {

    const { closeModalBtn, setAlertState, setAlertMessage } = props;

    const [getError, setError] = useState("");
    const [getPassword, setPassword] = useState(false);
    const [getPasswordDontMatchError, setPasswordDontMatchError] = useState(false);
    const [getVerificationCode, setVerificationCode] = useState(false);
    const [getFormData, setFormData] = useState({
        phonenumber: '',
        code: "",
        password: "",
        verify_password: "",
    });

    // const axiosInstance = axios.create({
    //     headers: {
    //         'Authorization': `Bearer ${TOKEN}`,
    //         'Content-Type': 'application/json',
    //     },
    // });

    const handleFormChange = (e) => {
        const { name, value } = e.target;

        const { password } = getFormData;

        if (name == "verify_password")
            password === value ? setPasswordDontMatchError(false) : setPasswordDontMatchError(true);

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { phonenumber } = getFormData;

        axios.post(FORGET_PASSWORD, {
            phonenumber: phonenumber,
        })
            .then((response) => {
                setVerificationCode(true);
            })
            .catch((error) => {
                setError(error.response.data.errors);
            })
    }

    const verifyCode = (e) => {
        e.preventDefault();
        const { phonenumber, code, password } = getFormData;

        axios.post(FORGET_PASSWORD, {
            phonenumber: phonenumber,
            verification_code: code,
            password: password
        })
            .then((response) => {
                setVerificationCode(true);
                setPassword(true);

                if (password) {
                    closeModalBtn();
                    setAlertState(true);
                    setAlertMessage("Password successfully changed");
                }
            })
            .catch((error) => {
                setError(error.response.data.errors);

                if (password) {
                    closeModalBtn();
                    setAlertState(false);
                    setAlertMessage("Failed to change the password");
                }
            })
    }

    // const sendPassword = (e) => {
    //     e.preventDefault();
    //     const { phonenumber, code, password } = getFormData;

    //     axios.post(FORGET_PASSWORD, {
    //         phonenumber: phonenumber,
    //         verification_code: code,
    //         password: password
    //     })
    //         .then((response) => {
    //             setPassword(true);
    //         })
    //         .catch((error) => {
    //             setError(error.response.data.errors);
    //         })
    // }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Forgot password</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div>
                                    <label>Phone number</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name="phonenumber"
                                        onChange={handleFormChange}
                                    />
                                </div>
                                {getVerificationCode ?
                                    <div>
                                        <label>Verification code</label>
                                        <input
                                            className='form-control'
                                            type='text'
                                            name="code"
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                    : null}
                                {getPassword ?
                                    <div>
                                        <div className="form-group">
                                            <label>Enter Password*</label>
                                            <input type="password" name="password" className="form-control" placeholder="Enter Your Password" onChange={handleFormChange} required />
                                        </div>

                                        <div className="form-group">
                                            <label>Verify Password*</label>
                                            <input type="password" name="verify_password" className="form-control" placeholder="Verify Password" onChange={handleFormChange} required />
                                            {getPasswordDontMatchError ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>Passwords to not match</span> : null}
                                        </div>
                                    </div>
                                    : null}
                                {getError ? <span style={{ color: "red", fontSize: 12 }}>{getError}</span> : null}
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={getVerificationCode ? verifyCode : handleFormSubmit}>
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;