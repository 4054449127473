//Main components
import React from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import BoardImg from "../../components/images/board.png";

function Introduce() {

    const navigate = useNavigate();

    return (
        <div>

            <Nav />

            <section class="page-title title-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>How to introduce yourself</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>How to introduce yourself</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <section class="about-section ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <div class="about-text">
                                <div class="section-title">
                                    <h2>How to introduce yourself</h2>
                                </div>

                                <p>
                                    How is it possible that one of the most basic aspects of business communication is also
                                    one of the most awkward ones? We`re talking about how to introduce yourself in a
                                    professional context –at a meeting, before a presentation, or meeting new clients. We are
                                    all able to say Hi, my name is Paola, and I am the Marketing Director at Talaera, but who are
                                    you beyond that? First impressions matter, and how you communicate in the
                                    workplace will help you have a better relationship with clients, leads, and coworkers. In
                                    this episode, you`ll learn an effective way to introduce yourself professionally in 3 easy
                                    steps. You can find the transcript at the bottom.
                                </p>
                                <p>
                                    Introductions are extremely important for your professional life. They are like your new
                                    business card. The first interaction you have with someone will impact how others
                                    perceive you, and also the relationship you’ll later have with them.
                                    The very first tip is to have it prepared. Don’t improvise. Have it ready for any professional
                                    situation. You can’t hesitate when you introduce yourself. So follow the steps below, write
                                    it down, practice, and make sure it’s ready and fresh for when you need it. Drop your
                                    scripted introduction in the comments and we&#39;ll tell you how you did!
                                </p>

                                <h4>A 3-step framework for professional introductions</h4>
                                <p>
                                    When it comes to introductions, every case is different, and you need to understand the
                                    room. When networking, for example, introductions tend to be shorter, but in a
                                    negotiation, you usually spend more time introducing yourself and bonding with the other
                                    party. But, in general, a professional introduction should include these three parts or
                                    steps: (1) who you are, (2) what you do, and (3) what others need to know. Once you have
                                    those three, wrap it up. Don&#39;t go on and on until the end of time. Let&#39;s look at each step
                                    individually. I&#39;ll explain them in-depth and provide some examples.
                                </p>

                                <h4>#1 Who you are</h4>
                                <p>
                                    The very first step is to mention your name. This part is easy! You can use the below
                                    phrases to introduce yourself:
                                </p>
                                <ul>
                                    <li>I don&#39;t think we&#39;ve met (before).</li>
                                    <li>I think we&#39;ve already met.</li>
                                    <li>My name is ...</li>
                                    <li>I&#39;m ...</li>
                                    <li>Nice to meet you; I&#39;m ...</li>
                                    <li>Pleased to meet you; I&#39;m ...</li>
                                    <li>Let me introduce myself; I&#39;m ...</li>
                                    <li>I&#39;d like to introduce myself; I&#39;m ...</li>
                                    <li>My name is Melanie, but you can call me Mel.</li>
                                </ul>


                                <h4>#2 What you do</h4>
                                <p>
                                    The second step might sound simple, but it&#39;s actually the most important part. Usually,
                                    people say their name and their job title. But what else is there to say about you? What is
                                    it exactly that you do? The tricky part is to find the right length of your elevator pitch. You need to provide some key points your audience can hang on to without having to write an
                                    essay.
                                </p>
                                <p>
                                    So, yes, you should still mention your job title, your company, perhaps even your
                                    department, but also add what you really do –in plain English. It should be short, so skip
                                    the details. A sentence is enough. What’s important is that people immediately
                                    understand what you do and want to work with you.
                                    When explaining what you do, don’t focus on tasks, focus on results. Talk about how you
                                    help people and be specific. If you can craft an introduction that’s focused on the results
                                    that the other person is looking for, you have it. You win. Think about the problems they
                                    may have and offer a solution. And most importantly, adjust this message according to
                                    your audience.
                                    You can just memorize the phrases below:
                                </p>
                                <ul>
                                    <li>I am a [job title] at [company].</li>
                                    <li>Officially, my job is to...</li>
                                    <li>But really, I...</li>
                                    <li>It’s all about...</li>
                                    <li>Which is a fancy way of saying…</li>
                                    Examples:
                                    <li>I’m a copywriter. Officially my job is to write content for advertising. But really, I help</li>
                                    companies tell compelling stories about their brands.
                                    <li>I am the COO, which is a fancy way of saying that I make sure that the company is run in the</li>
                                    most efficient way possible.
                                    Here are some additional expressions to describe your roles and responsibilities:
                                    <li>I’m a [job] at [company].</li>
                                    <li>I’m currently working as a [job].</li>
                                    <li>I’ve been with [company] since [time] / for [period].</li>
                                    <li>I work for [company].</li>
                                    <li>I work in [field/industry].</li>
                                    <li>I work with [department/person].</li>
                                    <li>I’m self-employed. / I&#39;m working as a freelancer. / I own my own company.</li>
                                    <li>My role is...</li>
                                    <li>My responsibilities include...</li>
                                    <li>I’m responsible for…</li>
                                    <li>I make sure that... / I ensure...</li>
                                    <li>I oversee… / I supervise...</li>
                                    <li>I handle...</li>
                                    <li>I deal with...
                                        Here&#39;s another example:</li>
                                    <li>I&#39;m a Talent Acquisition manager. I&#39;ve been working with Monsters Inc. since 2012, and my
                                        responsibilities include finding, recruiting, hiring, and retaining great candidates. It&#39;s all
                                        about making sure the team keeps growing nicely.</li>
                                </ul>


                                <h4>#3 What others need to know</h4>
                                <p>
                                    The last bit of a professional introduction consists of adding other nice facts that are
                                    relevant to the people and the context. Here, you can show your contribution (what you bring to the table), and you can set the expectations for the meeting or presentation going
                                    on.
                                    Here are some phrases you can use:
                                </p>
                                <ul>
                                    <li>Today, I’d like to walk you through…</li>
                                    <li>For the next 20 minutes, I’m going to explain…</li>
                                    <li>I’d like us to… and decide on a follow-up for [these other tasks]</li>
                                    <li>My purpose today is to share inside information about...</li>
                                    <li>I&#39;ll let you take the lead, but let me know if you&#39;d like me to jump in at some point.</li>
                                    This should also be very brief, so you’ll want to sum it up in a sentence. And that&#39;s it!
                                    You&#39;ve learned how to craft a personal introduction that&#39;s engaging and professional in 3
                                    easy steps.
                                    Now, it&#39;s your turn. Prepare your own introduction and remember to include all the steps
                                    we just mentioned.
                                    <li>Your name. </li>
                                    <li>What you do (which includes your job title and how you help people)</li>
                                    <li>A detail that the others need to know (what you bring to the table in that specific
                                        situation).
                                        And once you&#39;ve included them, end there. As the Alice in Wonderland quote goes...
                                        &quot;Begin at the beginning, and go on till you come to the end: then stop.&quot;</li>
                                </ul>
                            </div>
                        </div>
                        {/* <div class="col-lg-6">
                            <div class="about-img">
                                <img src={BoardImg} alt="about image" />
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <section class="use-section pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Easiest Way To Use</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>1</span>
                                <i class='flaticon-website'></i>
                                <h3>Browse Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>2</span>
                                <i class='flaticon-recruitment'></i>
                                <h3>Find Your Vaccancy</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="use-text">
                                <span>3</span>
                                <i class='flaticon-login'></i>
                                <h3>Submit Resume</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="why-choose-two pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Why You Choose Us Among Other Job Site?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-resume"></i>
                                <h3>Advertise Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-recruitment"></i>
                                <h3>Recruiter Profiles</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="choose-card">
                                <i class="flaticon-employee"></i>
                                <h3>Find Your Dream Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="grow-business pb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="grow-text">
                                <div class="section-title">
                                    <h2>Grow Your Business Faster With Premium Advertising</h2>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                                </p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit.
                                </p>

                                <div class="theme-btn">
                                    <a href="#" class="default-btn">Checkout More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="grow-img">
                                <img src="assets/img/grow-img.jpg" alt="grow image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="counter-section pt-100 pb-70">
                <div class="container">
                    <div class="row counter-area">
                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-resume"></i>
                                <h2><span>1225</span></h2>
                                <p>Job Posted</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-recruitment"></i>
                                <h2><span>145</span></h2>
                                <p>Job Filed</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-portfolio"></i>
                                <h2><span>170</span></h2>
                                <p>Company</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-employee"></i>
                                <h2><span>125</span></h2>
                                <p>Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-style-two ptb-100">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>What Client’s Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="row">
                        <div class="testimonial-slider-two owl-carousel owl-theme">
                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Alisa Meair</h3>
                                    <p>CEO of  Company</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Adam Smith</h3>
                                    <p>Web Developer</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>John Doe</h3>
                                    <p>Graphics Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="blog-section pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>News, Tips & Articles</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/1.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            How to Indroduce in Yourself in Job Interview?
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/2.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            Looking for Highly Motivated Product to Build
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/3.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            The Reason Why Software Develope is the Best Job
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
}

export default Introduce;

