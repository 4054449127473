//Core components
import axios from "axios";
import React, { useState } from "react";

//Global constants
import {
    TOKEN,
    MAKE_PAYMENT,
    CREATE_VERIFY_PHONE
} from "./Constants";


function BuyCredit(props) {

    const { closeModalBtn, phone } = props;

    const [getFormData, setFormData] = useState({
        amount: ''
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { amount } = getFormData;

        axios.post(MAKE_PAYMENT, {
            amount: amount,
            user: phone,
            //email: "nigel@kotikoti.co.zw"
        })
            .then((response) => {
                window.location.href = response.data.url;
            })
            .catch((error) => {
                console.error(error);
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Buy Credit</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <div>
                                    <label>Amount to pay</label>
                                    <input
                                        className='form-control'
                                        type='number'
                                        step="0.01"
                                        name="amount"
                                        onChange={handleFormChange}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn" style={{ background: "#dc3545", color: "white" }}>
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BuyCredit;