//Main components
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

//Project components
import Nav from "../components/Nav";
import Alert from "../components/Alert";
import Footer from "../components/Footer";
import Loader from "../components/Loader/Loader";
import {
    PHONE,
    GET_JOB,
    JOB_APPLY,
    TOKEN_KEY,
    JOB_APPLY_CHECK,
    PATIK_RETURN_URL,
    GET_JOBS_CATEGORY,
} from "../components/Constants";

function Job() {

    const [getJob, setJob] = useState({});
    const [getAlert, setAlert] = useState('');
    const [getJobApplied, setJobApplied] = useState(false);
    const [getRelatedJobs, setRelatedJobs] = useState([]);

    const { id } = useParams();

    const navigate = useNavigate();

    const isUserLoggedIn = localStorage.getItem(TOKEN_KEY);

    useEffect(() => {
        let userphone = localStorage.getItem(PHONE);
        let get_job_url = GET_JOB + "/" + id + "/" + userphone;
        let check_job_applied = JOB_APPLY_CHECK + "/" + id + "/" + userphone;
        //console.log("ID " + id);

        axios.get(get_job_url)
            .then((response) => {
                setJob(response.data);

                let related_jobs = GET_JOBS_CATEGORY + "/" + response.data.id + "/" + response.data.category_id;
                axios.get(related_jobs)
                    .then((related_jobs_response) => {
                        setRelatedJobs(related_jobs_response.data);
                        console.log(related_jobs_response.data);
                    })
                    .catch((related_jobs_error) => {
                        console.error(related_jobs_error);
                    })

            })
            .catch((error) => {
                console.error(error);
            })

        axios.get(check_job_applied)
            .then((response) => {
                setJobApplied(true);
            })
            .catch((error) => {
                setJobApplied(false);
            })
    }, [])

    const applyJob = (id) => {
        if (isUserLoggedIn) {

            let user = localStorage.getItem(PHONE);

            axios.post(JOB_APPLY, {
                job_id: id,
                user: user
            }).then((response) => {
                setJobApplied(true);
                setAlert({ "status": true, message: "Job successfully applied" });
                window.location.href = "/job";
            }).catch((error) => {
                setAlert({ "status": false, message: "Error applying for a job" });
                console.error(error);
            })
        } else {
            localStorage.setItem(PATIK_RETURN_URL, "/job/" + id);
            navigate("/login");
        }
    }

    return (
        <div>
            <Nav />

            <section class="page-title title-bg6">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>Job Details</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Job Details</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            {getAlert ?
                <Alert alertState={getAlert.status} message={getAlert.message} />
                : null
            }

            <section class="job-details ptb-100">
                <div class="container">
                    {getJob ?
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="job-details-text">
                                            <div class="job-card">
                                                <div class="row align-items-center">
                                                    <div class="col-md-2">
                                                        <div class="company-logo">
                                                            <i class="zmdi zmdi-settings" style={{ fontSize: "60px", color: "#fd1616" }}></i>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-10">
                                                        <div class="job-info">
                                                            <h3>{getJob.title}</h3>
                                                            <ul>
                                                                <li>
                                                                    <i class="zmdi zmdi-pin" style={{ marginRight: 5 }}></i>
                                                                    {getJob && getJob.suburb && getJob.suburb.suburb}
                                                                </li>
                                                                <li>
                                                                    <i class="zmdi zmdi-label" style={{ marginRight: 5 }}></i>
                                                                    {getJob && getJob.category && getJob.category.name}
                                                                </li>
                                                                {/* <li>
                                                                    <i class='bx bx-briefcase' ></i>
                                                                    Freelance
                                                                </li> */}
                                                            </ul>

                                                            <span>
                                                                <i class="zmdi zmdi-calendar-alt" style={{ marginRight: 5 }}></i>
                                                                {getJob.created_at}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="details-text">
                                                <h3>Description</h3>
                                                <p>{getJob.description}</p>
                                            </div>

                                            <div class="details-text">
                                                <h3>Requirements</h3>
                                                <p>{getJob.requirements}</p>
                                            </div>

                                            <div class="details-text">
                                                <h3>Job Details</h3>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Other applicants</span></td>
                                                                    <td>{getJob && getJob.applicants}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Location</span></td>
                                                                    <td>{getJob && getJob.suburb && getJob.suburb.suburb}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Job Type</span></td>
                                                                    <td>Full Time</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Language</span></td>
                                                                    <td>English</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Budgeted Amount (Negotiable)</span></td>
                                                                    <td>${getJob.salary}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Website</span></td>
                                                                    <td><a href="#">www.kotikoti.co.zw</a></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="theme-btn">
                                                {getJobApplied ?
                                                    <div>
                                                        <span style={{ padding: 5, backgroundColor: "#010c29", color: "white", borderRadius: 15 }}>Credits:{getJob && getJob.viewing_cost_credit}</span><br /><br />
                                                        <a onClick={() => { applyJob(getJob.id) }} class="default-btn">
                                                            Apply Now
                                                        </a>
                                                    </div>
                                                    :
                                                    <a class="default-btn" style={{ backgroundColor: "grey" }}>
                                                        Job applied
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4">
                                {getJob ?
                                    <div class="job-sidebar">
                                        <h3>Posted By</h3>
                                        <div class="posted-by">
                                            <i class="zmdi zmdi-account" style={{ fontSize: 30 }}></i>
                                            <h4>{getJob && getJob.user && getJob.user.name}</h4>

                                            <div>
                                                <span>{getJob && getJob.user && getJob.user.email}</span><br />
                                            </div>

                                            <div>
                                                <span>{getJob && getJob.user && getJob.user.phonenumber}</span>
                                                <br />
                                            </div>

                                            <span>{getJob.created_at}</span>
                                        </div>
                                    </div>
                                    : <Loader />
                                }

                                <div class="job-sidebar">
                                    <h3>Location</h3>
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.27991517034!2d-74.25987556253516!3d40.697670063539654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1588772651198!5m2!1sen!2sbd" frameborder="0"></iframe>
                                </div>

                                {/* <div class="job-sidebar">
                                    <h3>Keywords</h3>
                                    <ul>
                                        <li>
                                            <a href="#">Web Design</a>
                                        </li>
                                        <li>
                                            <a href="#">Data Sceince</a>
                                        </li>
                                        <li>
                                            <a href="#">SEO</a>
                                        </li>
                                        <li>
                                            <a href="#">Content Writter</a>
                                        </li>
                                        <li>
                                            <a href="#">Finance</a>
                                        </li>
                                        <li>
                                            <a href="#">Business</a>
                                        </li>
                                        <li>
                                            <a href="#">Education</a>
                                        </li>
                                        <li>
                                            <a href="#">Graphics</a>
                                        </li>
                                        <li>
                                            <a href="#">Video</a>
                                        </li>
                                    </ul>
                                </div> */}

                                {/* <div class="job-sidebar social-share">
                                    <h3>Share In</h3>
                                    <ul>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class="zmdi zmdi-facebook-box"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class="zmdi zmdi-twitter-box"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class="zmdi zmdi-instagram"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" target="_blank">
                                                <i class="zmdi zmdi-linkedin-box"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        : <Loader />
                    }
                </div>
            </section>

            {getRelatedJobs ?
                <section class="job-style-two pt-100 pb-70">
                    <div class="container">
                        <div class="section-title text-center">
                            <h2>Jobs You May Be Interested In</h2>
                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p> */}
                        </div>

                        <div class="row">

                            {getRelatedJobs.map((job, index) => (
                                <div key={index} class="col-lg-12">
                                    <div class="job-card-two">
                                        <div class="row align-items-center">
                                            <div class="col-md-1">
                                                <div class="company-logo">
                                                    <a href="job-details.html">
                                                        <i class="zmdi zmdi-settings" style={{ fontSize: "60px", color: "#fd1616" }}></i>
                                                    </a>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="job-info">
                                                    <h3>
                                                        <a href="#">{job.title} </a>
                                                    </h3>
                                                    <ul>
                                                        <li>
                                                            <i class="zmdi zmdi-label" style={{ marginRight: 5 }}></i>
                                                            {job && job.category && job.category.name}
                                                        </li>
                                                        <li>
                                                            <i class="zmdi zmdi-money-box"></i>
                                                            ${job.salary}
                                                        </li>
                                                        <li>
                                                            <i class="zmdi zmdi-pin" style={{ marginRight: 5 }}></i>
                                                            {job && job.suburb && job.suburb.suburb}
                                                        </li>
                                                        <li>
                                                            <i class="zmdi zmdi-calendar-alt" style={{ marginRight: 5 }}></i>
                                                            {job.created_at}
                                                        </li>
                                                    </ul>

                                                    <span>Full Time</span>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="theme-btn text-end">
                                                    <a onClick={() => { window.location.href = "/job/" + job.id }} class="default-btn">
                                                        Browse Job
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            }

                        </div>
                    </div>
                </section>
                : <Loader />
            }
            {/* <section class="subscribe-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <form class="newsletter-form" data-toggle="validator">
                                <input type="email" class="form-control" placeholder="Enter your email" name="email" required autocomplete="off" />

                                <button class="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />
        </div>
    );
}

export default Job;