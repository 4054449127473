//Main components
import React from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import BoardImg from "../components/images/board.png";

function AboutUs() {

    const navigate = useNavigate();

    return (
        <div>

            <Nav />

            <section class="page-title title-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <h2>About Us</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
                <div class="lines">
                    <div class="line"></div>
                    <div class="line"></div>
                    <div class="line"></div>
                </div>
            </section>

            <section class="about-section ptb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-text">
                                <div class="section-title">
                                    <h2>How We Started</h2>
                                </div>

                                <p>
                                    Koti Koti aims to create a trusted, dynamic and efficient platform that connects buyers and sellers in urgent need of products and services. We provide a nation wide one stop platform where customers can find essential services with utmost urgency at their comfort. Our platform connects buyers and sellers in real-time, facilitating quick and reliable services. We guarantee to create a working platform that is safe, enjoyable and profitable to all.
                                </p>
                                <h4>Our values</h4>
                                <ol>
                                    <li>
                                        Reliability:- we aim to provide a dependable platform that users can count on to connect them with trusted service providers who deliver high quality services.
                                    </li>
                                    <li>
                                        Flexibility:- we understand that everyone's needs and preferences are unique. We embrace flexibility and strive to accomodate a wide range of services and service providers, allowing users to find the perfect match that suites their specific requirements.
                                    </li>
                                    <li>
                                        Trustworthiness:- we understand the importance of trust in building long term relationships, and we strive to be a trusted platform for both service providers and clients, safeguarding their interests and maintaining confidentiality.
                                    </li>
                                    <li>
                                        Respect:- we treat all service providers and clients with respect, valuing their unique skills, expertise and requirements, fostering a culture of inclusivity and fairness.
                                    </li>
                                    <li>
                                        Community:- we value the sense of community that our platform fosters. We encourage service providers and clients to engage with each other, share their experiences, and support one another. We believe that by building a strong community, we can create a positive and enriching environment for everyone involved.
                                    </li>
                                    <li>
                                        Creating a safe environment for service providers and clients.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-img">
                                <img src={BoardImg} alt="about image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="use-section pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Easiest Way To Use</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>1</span>
                                <i class='flaticon-website'></i>
                                <h3>Browse Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="use-text">
                                <span>2</span>
                                <i class='flaticon-recruitment'></i>
                                <h3>Find Your Vaccancy</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="use-text">
                                <span>3</span>
                                <i class='flaticon-login'></i>
                                <h3>Submit Resume</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="why-choose-two pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Why You Choose Us Among Other Job Site?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-resume"></i>
                                <h3>Advertise Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="choose-card">
                                <i class="flaticon-recruitment"></i>
                                <h3>Recruiter Profiles</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="choose-card">
                                <i class="flaticon-employee"></i>
                                <h3>Find Your Dream Job</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore   </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="grow-business pb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="grow-text">
                                <div class="section-title">
                                    <h2>Grow Your Business Faster With Premium Advertising</h2>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                                </p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit.
                                </p>

                                <div class="theme-btn">
                                    <a href="#" class="default-btn">Checkout More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="grow-img">
                                <img src="assets/img/grow-img.jpg" alt="grow image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="counter-section pt-100 pb-70">
                <div class="container">
                    <div class="row counter-area">
                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-resume"></i>
                                <h2><span>1225</span></h2>
                                <p>Job Posted</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-recruitment"></i>
                                <h2><span>145</span></h2>
                                <p>Job Filed</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-portfolio"></i>
                                <h2><span>170</span></h2>
                                <p>Company</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-employee"></i>
                                <h2><span>125</span></h2>
                                <p>Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-style-two ptb-100">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>What Client’s Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="row">
                        <div class="testimonial-slider-two owl-carousel owl-theme">
                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Alisa Meair</h3>
                                    <p>CEO of  Company</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Adam Smith</h3>
                                    <p>Web Developer</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>John Doe</h3>
                                    <p>Graphics Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section class="blog-section pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>News, Tips & Articles</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/1.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            How to Indroduce in Yourself in Job Interview?
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/2.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            Looking for Highly Motivated Product to Build
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="blog-card">
                                <div class="blog-img">
                                    <a href="blog-details.html">
                                        <img src="assets/img/blog/3.jpg" alt="blog image" />
                                    </a>
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class='bx bxs-user'></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class='bx bx-calendar'></i>
                                            7 Feb, 2021
                                        </li>
                                    </ul>

                                    <h3>
                                        <a href="blog-details.html">
                                            The Reason Why Software Develope is the Best Job
                                        </a>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>

                                    <a href="blog-details.html" class="blog-btn">
                                        Read More
                                        <i class='bx bx-plus bx-spin'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default AboutUs;

