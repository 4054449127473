//Core components
import axios from "axios";
import React, { useState } from "react";

//Global constants
import {
    TOKEN,
    CREATE_VERIFY_PHONE
} from "./Constants";


function PhoneVerify(props) {

    const { closeModalBtn, setAlert, setAlertMessage, phone } = props;

    const [getFormData, setFormData] = useState({
        code: ''
    });

    // const axiosInstance = axios.create({
    //     headers: {
    //         'Authorization': `Bearer ${TOKEN}`,
    //         'Content-Type': 'application/json',
    //     },
    // });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const { code } = getFormData;

        axios.post(CREATE_VERIFY_PHONE, {
            code: code,
            phonenumber: phone
        })
            .then((response) => {
                setAlertMessage(response.data);
                setAlert(true);
                closeModalBtn();
                console.log("Successfully sent " + JSON.stringify(response.data));
            })
            .catch((error) => {
                setAlertMessage("The code do not exist the one we have!");
                closeModalBtn();
                console.error("Error creating leave: ", error);
            })
    }


    return (
        <div>

            <div className="modal-overlay"></div>
            <div className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Level Application Form</h5>
                            <button type="button" className="close" onClick={closeModalBtn}>
                                <span>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleFormSubmit}>
                                <div>
                                    <label>Verification code</label>
                                    <input
                                        className='form-control'
                                        type='text'
                                        name="code"
                                        onChange={handleFormChange}
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" onClick={closeModalBtn}>
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Save changes
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PhoneVerify;