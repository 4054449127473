//Main components
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../../components/Nav";
import Alert from "../../components/Alert";
import Footer from "../../components/Footer";
import ForgotPassword from "../../components/ForgotPassword";
import {
    ROLE,
    USER,
    NAME,
    PHONE,
    TOKEN_KEY,
    LOGIN_USER,
    PATIK_RETURN_URL,
} from "../../components/Constants";

function Login() {

    const [getAlert, setAlert] = useState("");
    const [getAlertBoolean, setAlertBoolean] = useState("");
    const [getFormError, setFormError] = useState({});
    const [getForgotPassword, setForgotPassword] = useState(false);
    const [getFormData, setFormData] = useState({
        phoneEmail: '',
        password: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        const login = localStorage.getItem(TOKEN_KEY);
        const return_url = localStorage.getItem(PATIK_RETURN_URL);

        if (login) {
            if (return_url)
                navigate("/" + return_url);
            else
                navigate("/dashboard");
        }

    }, []);



    const formChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...getFormData,
            [name]: value
        });
    }

    const submitFormData = (e) => {
        e.preventDefault();
        const { phoneEmail, password } = getFormData;

        axios.post(LOGIN_USER, {
            phoneEmail: phoneEmail,
            password: password
        })
            .then((response) => {
                localStorage.setItem(NAME, response.data.user.name);
                localStorage.setItem(ROLE, response.data.user_role);
                localStorage.setItem(TOKEN_KEY, response.data.token);
                localStorage.setItem(PHONE, response.data.user.phonenumber);
                localStorage.setItem(USER, response.data.user.id);

                let returnUrlSet = localStorage.getItem(PATIK_RETURN_URL);
                returnUrlSet ? navigate(returnUrlSet) : navigate("/dashboard");
                //alert(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.error(error);
                //alert(JSON.stringify(error));
                //if (error.response.status == 404) {
                setFormError(error.response.data.errors)
                //}
            })
    }

    const forgotPassword = () => {
        setForgotPassword(true);
    }

    const closeForgotBtn = () => {
        setForgotPassword(false);
    }

    const setAlertMessage = (message) => {
        setAlert(message);
    }

    const setAlertBool = (bool) => {
        setAlertBoolean(bool)
    }

    return (
        <div>
            <Nav />

            <section className="page-title title-bg12">
                <div className="d-table">
                    <div className="d-table-cell">
                        <h2>Sign In</h2>
                        <ul>
                            <li>
                                <a href="index.html">Home</a>
                            </li>
                            <li>Sign In</li>
                        </ul>
                    </div>
                </div>
                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </section>

            <div className="signin-section ptb-100">
                {getForgotPassword ? <ForgotPassword closeModalBtn={closeForgotBtn} setAlertMessage={setAlertMessage} setAlertState={setAlertBool} /> : null}
                {getAlertBoolean ? <Alert alertState={getAlertBoolean} message={getAlert} /> : null}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 offset-md-2 offset-lg-3">
                            <form className="signin-form">
                                <div className="form-group">
                                    <label>Enter Email or Phone Number</label>
                                    <input type="text" name="phoneEmail" className="form-control" placeholder="Enter Your Email or Phone Number" onChange={formChange} required />
                                    {getFormError.phone ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.phone}</span> : null}
                                    {getFormError.email ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.email}</span> : null}
                                </div>

                                <div className="form-group">
                                    <label>Enter Password</label>
                                    <input type="password" name="password" className="form-control" placeholder="Enter Your Password" onChange={formChange} required />
                                    {getFormError.password ? <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>{getFormError.password}</span> : null}
                                </div>


                                <div className="signin-btn text-center">
                                    <button type="submit" onClick={submitFormData}>Sign In</button>
                                </div>

                                {/* <div className="other-signin text-center">
                                    <span>Or sign in with</span>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className='bx bxl-google'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className='bx bxl-facebook'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className='bx bxl-twitter'></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className='bx bxl-linkedin'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}

                                <div className="create-btn text-center">
                                    <p>Not have an account?
                                        <a onClick={() => navigate("/register")} style={{ cursor: "pointer" }}>
                                            Create an account
                                        </a>
                                    </p>
                                    <span style={{ color: "#fd1616", cursor: "pointer" }} onClick={forgotPassword}>Forgot Password?</span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section className="subscribe-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <form className="newsletter-form" data-toggle="validator">
                                <input type="email" className="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button className="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" className="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}


            <Footer />
        </div>
    );
}

export default Login;