function Alert(props) {
    const { alertState, message } = props;
    return (
        <div>
            {alertState ?
                <div class="alert alert-success" role="alert">
                    {message}
                </div>
                :
                <div class="alert alert-danger" role="alert">
                    {message}
                </div>
            }
        </div>
    );
}

export default Alert;