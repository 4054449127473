//Main components
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Dart from "../components/images/dart.jpg";
import {
    PATIK_SEARCH,
    PATIK_SUBURB,
    PATIK_CATEGORY,
    GET_CATEGORIES,
    GET_SUBURB_LIST
} from "../components/Constants";
import Loader from "../components/Loader/Loader";

function Dashboard() {

    const [getSuburbs, setSuburbs] = useState('');
    const [getCategories, setCategories] = useState('');
    const [getSearchForm, setSearchForm] = useState({
        search_job: '',
        search_suburb: '',
        search_category: ''
    });

    const navigate = useNavigate();

    useEffect(() => {

        localStorage.removeItem(PATIK_SEARCH);
        localStorage.removeItem(PATIK_SUBURB);
        localStorage.removeItem(PATIK_CATEGORY);

        axios.get(GET_CATEGORIES)
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.error(error);
            })

        axios.get(GET_SUBURB_LIST)
            .then((response) => {
                setSuburbs(response.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }, [])

    const handleSearchFormChange = (e) => {
        const { name, value } = e.target;

        setSearchForm({
            ...getSearchForm,
            [name]: value
        });
    }

    const handleSearchFormSubmit = (e) => {
        e.preventDefault();

        const { search_job, search_suburb, search_category } = getSearchForm;

        localStorage.setItem(PATIK_SEARCH, search_job);
        localStorage.setItem(PATIK_SUBURB, search_suburb);
        localStorage.setItem(PATIK_CATEGORY, search_category);

        navigate("/jobadd");
    }

    const categoryPage = (category_name) => {
        localStorage.setItem(PATIK_CATEGORY, category_name);
        navigate("/jobadd");
    }

    return (
        <div>

            <Nav />
            <div class="banner-style-three">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="find-section pb-100">
                                <div class="container">

                                    <div class="banner-text">
                                        <h1>Find professionals near you</h1>
                                    </div>
                                    <form class="find-form" style={{ marginTop: 20, width: "80%" }}>
                                        <div class="row">
                                            <div class="col-lg-8">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" name="search_job" placeholder="Services you are looking for?" onChange={handleSearchFormChange} required />
                                                    <i class="zmdi zmdi-search"></i>
                                                </div>
                                            </div>

                                            {/* <div class="col-lg-3">
                                                <div class="form-group">
                                                    <select class="nice-select category list" name="search_suburb" onChange={handleSearchFormChange} required>
                                                        <option data-display="Category" className="option">Location</option>
                                                        {getSuburbs ?
                                                            getSuburbs.map((suburb, index) => (
                                                                <option key={index} value={suburb.suburb}>{suburb.suburb}</option>
                                                            ))
                                                            : null
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-lg-3">
                                                <select class="nice-select category list" name="search_category" onChange={handleSearchFormChange} required>
                                                    <option data-display="Category" className="option">Category</option>
                                                    {getCategories ?
                                                        getCategories.map((category, index) => (
                                                            <option key={index} value={category.name}>{category.name}</option>
                                                        ))
                                                        : null
                                                    }
                                                </select>
                                            </div> */}

                                            <div class="col-lg-4">
                                                <button class="find-btn" onClick={handleSearchFormSubmit}>
                                                    Find A Professional
                                                    <i class="zmdi zmdi-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* <div class="banner-text">
                                <span>Find Jobs, Employment & Career Opportunities</span>
                                <h1>Find professionals near you</h1>
                                <p>Post a job listing, find a professional to do the job for you at competitive market prices that doesn't compromise quality.</p>

                                <div class="theme-btn">
                                    <a onClick={() => { navigate("/jobs") }} style={{ cursor: "pointer" }} class="default-btn active">Finds jobs</a>
                                    <a onClick={() => { navigate("/contact") }} style={{ cursor: "pointer" }} class="default-btn">Contact Us</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>



            <div class="category-style-two pb-70" style={{ marginTop: 80 }}>
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Popular Jobs Category</h2>
                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus</p> */}
                    </div>

                    <div class="row">
                        {getCategories ? getCategories.slice(0, 8).map((category, index) => (
                            <div class="col-lg-3 col-sm-6" key={index}>
                                <a onClick={() => { categoryPage(category.name) }} style={{ cursor: "pointer" }}>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-device-hub"></i>
                                        <h3>{category.name}</h3>
                                        <p>{category.jobs.filter(item => typeof item === "object" && !Array.isArray(item)).length} jobs</p>
                                    </div>
                                </a>
                            </div>
                        ))
                            : <Loader />
                        }


                        {/* 
                            <div class="col-lg-3 col-sm-6">
                                <a>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-airplay"></i>
                                        <h3>DSTV satelite and home installation</h3>
                                        <p>8 new Job</p>
                                    </div>
                                </a>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <a>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-format-color-fill"></i>
                                        <h3>Boreholes installers / borehole accessories</h3>
                                        <p>9 new Job</p>
                                    </div>
                                </a>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <a>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-scissors"></i>
                                        <h3>Handyman</h3>
                                        <p>6 new Job</p>
                                    </div>
                                </a>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <a>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-eyedropper"></i>
                                        <h3>Agricultural services</h3>
                                        <p>5 new Job</p>
                                    </div>
                                </a>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <a>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-car"></i>
                                        <h3>Automotive</h3>
                                        <p>12 new Job</p>
                                    </div>
                                </a>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <a>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-book"></i>
                                        <h3>Education</h3>
                                        <p>15 new Job</p>
                                    </div>
                                </a>
                            </div>

                            <div class="col-lg-3 col-sm-6">
                                <a>
                                    <div class="category-item">
                                        <i class="zmdi zmdi-edit"></i>
                                        <h3>General builder</h3>
                                        <p>5 new Job</p>
                                    </div>
                                </a>
                            </div> 
                        */}
                    </div>
                </div>
            </div>

            {/* <div class="grow-business pb-100">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7">
                            <div class="grow-text">
                                <div class="section-title">
                                    <h2>Grow Your Business Faster With Premium Advertising</h2>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.
                                </p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit.
                                </p>

                                <div class="theme-btn">
                                    <a href="#" class="default-btn">Checkout More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-5">
                            <div class="grow-img">
                                <img src="assets/img/grow-img.jpg" alt="grow image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div class="video-section ptb-100">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="video-text text-center">
                                <h2>Our Working Process</h2>
                                <a href="https://www.youtube.com/watch?v=LXb3EKWsInQ" class="popup-youtube">
                                    <i class='bx bx-play'></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section class="job-style-two pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>Jobs You May Be Interested In</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/1.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Web Designer, Graphic Designer, UI/UX Designer </a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Graphics Designer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $35000-$38000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Wellesley Rd, London
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    9 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/2.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Website Developer & Software Developer</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Web Developer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $3000-$8000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Garden Road, UK
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    5 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/3.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Application Developer & Web Designer</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase'></i>
                                                    App Developer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase'></i>
                                                    $3000-$4000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    State City, USA
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    8 days ago
                                                </li>
                                            </ul>

                                            <span>Part-Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/4.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">Frontend & Backend Developer</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Web Developer
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $5000-$8000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Drive Post NY 676
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    1 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="job-card-two">
                                <div class="row align-items-center">
                                    <div class="col-md-1">
                                        <div class="company-logo">
                                            <a href="job-details.html">
                                                <img src="assets/img/company-logo/5.png" alt="logo" />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-md-8">
                                        <div class="job-info">
                                            <h3>
                                                <a href="job-details.html">IT Department & Manager</a>
                                            </h3>
                                            <ul>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    Manager
                                                </li>
                                                <li>
                                                    <i class='bx bx-briefcase' ></i>
                                                    $35000-$38000
                                                </li>
                                                <li>
                                                    <i class='bx bx-location-plus'></i>
                                                    Wellesley Rd, London
                                                </li>
                                                <li>
                                                    <i class='bx bx-stopwatch' ></i>
                                                    7 days ago
                                                </li>
                                            </ul>

                                            <span>Full Time</span>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="theme-btn text-end">
                                            <a href="job-details.html" class="default-btn">
                                                Browse Job
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section class="company-section pt-100 pb-70">
                <div class="container">
                    {/* <div class="section-title text-center">
                        <h2>Top Companies</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div> */}

                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a>
                                        <img src={Dart} alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Dart Media</h3>
                                    <p>
                                        <i class="zmdi zmdi-info"></i>
                                        Media house mainly tv, radio, podcast, video shoots and photoshoot.
                                    </p>
                                    <p>
                                        <i class="zmdi zmdi-pin"></i>
                                        103 N. Mandela Avenue, Harare
                                    </p>
                                    <a class="company-btn">
                                        Sponsored
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a>
                                        <img src="assets/img/top-company/2.png" alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Trout Design</h3>
                                    <p>
                                        <i class='bx bx-location-plus'></i>
                                        Park Avenue, Mumbai
                                    </p>
                                    <a class="company-btn">
                                        35 Open Position
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a>
                                        <img src="assets/img/top-company/3.png" alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Resland LTD</h3>
                                    <p>
                                        <i class='bx bx-location-plus'></i>
                                        Betas Quence, London
                                    </p>
                                    <a class="company-btn">
                                        20 Open Position
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-sm-6">
                            <div class="company-card">
                                <div class="company-logo">
                                    <a>
                                        <img src="assets/img/top-company/4.png" alt="company logo" />
                                    </a>
                                </div>
                                <div class="company-text">
                                    <h3>Lawn Hopper</h3>
                                    <p>
                                        <i class='bx bx-location-plus'></i>
                                        Wellesley Rd, London
                                    </p>
                                    <a class="company-btn">
                                        45 Open Position
                                    </a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* <section class="browse-job ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="browse-jobs-text">
                                <div class="section-title">
                                    <h2>Browse Hundred of Jobs</h2>
                                    <p>We are efficiently delivering tons of jobs straight to your pocket</p>
                                </div>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>

                                <div class="app-btn">
                                    <a href="#" class="app-store active">
                                        <i class='bx bxl-apple' ></i>
                                        <ul>
                                            <li>Download on the</li>
                                            <li>App Store</li>
                                        </ul>
                                    </a>

                                    <a href="#" class="play-store">
                                        <i class='bx bxl-play-store'></i>
                                        <ul>
                                            <li>Get it</li>
                                            <li>Google Play</li>
                                        </ul>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="browse-job-img">
                    <img src="assets/img/mobile.png" alt="mobile image" />
                </div>
            </section> */}

            {/* <div class="counter-section pt-100 pb-70">
                <div class="container">
                    <div class="row counter-area">
                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-resume"></i>
                                <h2><span>1225</span></h2>
                                <p>Job Posted</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-recruitment"></i>
                                <h2><span>145</span></h2>
                                <p>Job Filed</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-portfolio"></i>
                                <h2><span>170</span></h2>
                                <p>Company</p>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="counter-text">
                                <i class="flaticon-employee"></i>
                                <h2><span>125</span></h2>
                                <p>Members</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div class="testimonial-style-two ptb-100">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>What Client’s Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>

                    <div class="row">
                        <div class="testimonial-slider-two owl-carousel owl-theme">
                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Alisa Meair</h3>
                                    <p>CEO of  Company</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>Adam Smith</h3>
                                    <p>Web Developer</p>
                                </div>
                            </div>

                            <div class="testimonial-items">
                                <div class="testimonial-text">
                                    <i class='flaticon-left-quotes-sign'></i>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do mod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's.</p>
                                </div>

                                <div class="testimonial-info-text">
                                    <h3>John Doe</h3>
                                    <p>Graphics Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <section class="why-choose">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-7 p-0">
                            <div class="why-choose-text pt-100 pb-70">
                                <div class="section-title text-center">
                                    <h2>Why You Choose Us?</h2>
                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorei.</p> */}
                                </div>

                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-group align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Accredited professionals</h5>
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-research align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Easy To Find Canditate</h5>
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-discussion align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Easy To Communicate</h5>
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6">
                                        <div class="media">
                                            <i class="flaticon-recruitment align-self-center mr-3"></i>
                                            <div class="media-body">
                                                <h5 class="mt-0">Nation Pool Recruitment Option</h5>
                                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div class="row counter-area">
                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>127</span></h2>
                                            <p>Job Posted</p>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>137</span></h2>
                                            <p>Job Filed</p>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>180</span></h2>
                                            <p>Company</p>
                                        </div>
                                    </div>

                                    <div class="col-md-3 col-6">
                                        <div class="counter-text">
                                            <h2><span>144</span></h2>
                                            <p>Members</p>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div class="col-lg-5 p-0">
                            <div class="why-choose-img">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="blog-section pt-100 pb-70">
                <div class="container">
                    <div class="section-title text-center">
                        <h2>News, Tips & Articles</h2>
                    </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    {/* <a>
                                        <img src="assets/img/blog/1.jpg" alt="blog image" />
                                    </a> */}
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class="zmdi zmdi-account-circle"></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class="zmdi zmdi-calendar-alt"></i>
                                            11 March, 2023
                                        </li>
                                    </ul>

                                    <h3>
                                        <a style={{ cursor: "pointer" }} onClick={() => { navigate("/serviceprovider") }}>
                                            Choosing a service provider
                                        </a>
                                    </h3>
                                    <p>Even if the managers had the technical skills to attend to all the needs of their facilities,
                                        outsourcing saves time, money, and hassle. However, the decision process can be
                                        overwhelming with the proliferation of managed services providers (MSP).</p>

                                    <a class="blog-btn" style={{ cursor: "pointer" }} onClick={() => { navigate("/serviceprovider") }}>
                                        Read More
                                        <i class="zmdi zmdi-spinner"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="blog-card">
                                <div class="blog-img">
                                    {/* <a>
                                        <img src="assets/img/blog/2.jpg" alt="blog image" />
                                    </a> */}
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class="zmdi zmdi-account-circle"></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class="zmdi zmdi-calendar-alt"></i>
                                            11 March, 2023
                                        </li>
                                    </ul>

                                    <h3>
                                        <a style={{ cursor: "pointer" }} onClick={() => { navigate("/introduce") }}>
                                            How to introduce yourself professionally.
                                        </a>
                                    </h3>
                                    <p>How is it possible that one of the most basic aspects of business communication is also
                                        one of the most awkward ones?</p>

                                    <a class="blog-btn" style={{ cursor: "pointer" }} onClick={() => { navigate("/introduce") }}>
                                        Read More
                                        <i class="zmdi zmdi-spinner"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="blog-card">
                                <div class="blog-img">
                                    {/* <a>
                                        <img src="assets/img/blog/3.jpg" alt="blog image" />
                                    </a> */}
                                </div>
                                <div class="blog-text">
                                    <ul>
                                        <li>
                                            <i class="zmdi zmdi-account-circle"></i>
                                            Admin
                                        </li>
                                        <li>
                                            <i class="zmdi zmdi-calendar-alt"></i>
                                            11 March, 2023
                                        </li>
                                    </ul>

                                    <h3>
                                        <a style={{ cursor: "pointer" }} onClick={() => { navigate("/safeguard") }}>
                                            Safe guarding your valuables
                                        </a>
                                    </h3>
                                    <p>Success in life has many great benefits, including the ability to invest in objects of
                                        value. After working hard to acquire these items, you want to make sure that your
                                        valuables are protected, as certain items are easy to carry off and frequently the
                                        target of would-be thieves.</p>

                                    <a class="blog-btn" style={{ cursor: "pointer" }} onClick={() => { navigate("/safeguard") }}>
                                        Read More
                                        <i class="zmdi zmdi-spinner"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section class="subscribe-section">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <form class="newsletter-form" data-toggle="validator">
                                <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button class="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" class="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section> */}

            <Footer />

        </div>
    );
}

export default Dashboard;