//Main components
import React from "react";
import { useNavigate } from "react-router-dom";

//Project components
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function Candidate() {

    const navigate = useNavigate();

    return (
        <div>
            <Nav />

            <section className="page-title title-bg8">
                <div className="d-table">
                    <div className="d-table-cell">
                        <h2>Candidates Details</h2>
                        <ul>
                            <li>
                                <a onClick={() => { navigate("/dashboard") }}>Home</a>
                            </li>
                            <li>Candidates Details</li>
                        </ul>
                    </div>
                </div>
                <div className="lines">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </section>

            <section className="candidate-details pt-100 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="candidate-profile">
                                <img src="assets/img/client-1.png" alt="candidate image" />
                                <h3>John Smith</h3>
                                <p>Web Developer</p>

                                <ul>
                                    <li>
                                        <a href="tel:+100230342">
                                            <i className='bx bxs-phone'></i>
                                            +101 023 0342
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:john@gmail.com">
                                            <i className='bx bxs-location-plus'></i>
                                            john@gmail.com
                                        </a>
                                    </li>
                                </ul>

                                <div className="candidate-social">
                                    <a href="#" target="_blank"><i className="bx bxl-facebook"></i></a>
                                    <a href="#" target="_blank"><i className="bx bxl-twitter"></i></a>
                                    <a href="#" target="_blank"><i className="bx bxl-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="candidate-info-text">
                                <h3>About Me</h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                            </div>

                            <div className="candidate-info-text candidate-education">
                                <h3>Education</h3>

                                <div className="education-info">
                                    <h4>School</h4>
                                    <p>Amherst School, USA</p>
                                    <span>2000-2010</span>
                                </div>

                                <div className="education-info">
                                    <h4>College</h4>
                                    <p>Swarthmore College, USA</p>
                                    <span>2010-2012</span>
                                </div>

                                <div className="education-info">
                                    <h4>University</h4>
                                    <p>Princeton University, USA</p>
                                    <span>2012-2016</span>
                                </div>
                            </div>

                            <div className="candidate-info-text candidate-experience">
                                <h3>Experience</h3>

                                <ul>
                                    <li>Proficient in HTML, CSS, Server-Scripting, C/C++, and Oracle</li>
                                    <li>Experience with SEO</li>
                                    <li>Experience Teaching Web Development</li>
                                    <li>Knowledgeable in Online Advertising</li>
                                    <li>Expert in LAMP Web Service Stacks</li>
                                </ul>
                            </div>

                            <div className="candidate-info-text candidate-skill">
                                <h3>Skills</h3>

                                <ul>
                                    <li>HTMl</li>
                                    <li>CSS</li>
                                    <li>JS</li>
                                    <li>PHP</li>
                                    <li>Oracle</li>
                                    <li>C/C++</li>
                                    <li>SQL</li>
                                    <li>Ruby</li>
                                </ul>
                            </div>

                            <div className="candidate-info-text text-center">
                                <div className="theme-btn">
                                    <a href="#" className="default-btn">Hire Me</a>
                                    <a href="#" className="default-btn">Download CV</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="subscribe-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="section-title">
                                <h2>Get New Job Notifications</h2>
                                <p>Subscribe & get all related jobs notification</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <form className="newsletter-form" data-toggle="validator">
                                <input type="email" className="form-control" placeholder="Enter your email" name="EMAIL" required autocomplete="off" />

                                <button className="default-btn sub-btn" type="submit">
                                    Subscribe
                                </button>

                                <div id="validator-newsletter" className="form-result"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default Candidate;